/* global axios */
import ApiClient from './ApiClient';

class BillingApi extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  getPlans() {
    return axios.get(`${this.url}chat_packages`);
  }

  sendPlans(current_package) {
    return axios.post(`${this.url}current_packages`, { current_package });
  }

  sendtoken(token) {
    return axios.post(`${this.url}stripe_customers`, token);
  }

  subscribe() {
    return axios.post(`${this.url}stripe_customers/subscribe`);
  }

  fetchCard() {
    return axios.get(`${this.url}stripe_customers/fetch_card`);
  }

  fetchCurrentPackage(id) {
    return axios.get(`${this.url}current_packages/${id}`);
  }

  isSubscribed(id) {
    return axios.get(`${this.url}chat_packages/${id}`);
  }

  getHistory() {
    return axios.get(`${this.url}stripe_customers/fetch_payments`);
  }

  postContact(data) {
    return axios.post(`${this.url}contact_us`, data);
  }
}

export default new BillingApi();
