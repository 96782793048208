<template>
  <div>
    <h4 class="hero--title">
      Account Information
    </h4>
    <form class="signup--box login-box" @submit.prevent="submit">
      <woot-input
        v-model="credentials.fullName"
        :class="{ error: $v.credentials.fullName.$error }"
        :label="$t('REGISTER.FULL_NAME.LABEL')"
        :placeholder="$t('REGISTER.FULL_NAME.PLACEHOLDER')"
        :error="
          $v.credentials.fullName.$error ? $t('REGISTER.FULL_NAME.ERROR') : ''
        "
        @blur="$v.credentials.fullName.$touch"
      />
      <woot-input
        v-model.trim="credentials.email"
        type="email"
        :class="{ error: $v.credentials.email.$error }"
        :label="$t('REGISTER.EMAIL.LABEL')"
        :placeholder="$t('REGISTER.EMAIL.PLACEHOLDER')"
        :error="$v.credentials.email.$error ? $t('REGISTER.EMAIL.ERROR') : ''"
        @blur="$v.credentials.email.$touch"
      />
      <div class="row">
        <div class="medium-12 columns">
          <label :class="{ error: $v.credentials.phoneNumber.$error }">
            {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.LABEL') }}
            <input
              v-model.trim="credentials.phoneNumber"
              type="text"
              :placeholder="$t('CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')"
              @input="$v.credentials.phoneNumber.$touch"
            />
            <span v-if="$v.credentials.phoneNumber.$error" class="message">
              Phone number should not be empty and of E.164 format
            </span>
          </label>
          <div
            v-if="$v.credentials.phoneNumber.$error || !credentials.phoneNumber"
            class="callout small warning"
          >
            {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.HELP') }}
          </div>
        </div>
      </div>
      <woot-input
        v-model="credentials.accountName"
        :class="{ error: $v.credentials.accountName.$error }"
        :label="$t('REGISTER.ACCOUNT_NAME.LABEL')"
        :placeholder="$t('REGISTER.ACCOUNT_NAME.PLACEHOLDER')"
        :error="
          $v.credentials.accountName.$error
            ? $t('REGISTER.ACCOUNT_NAME.ERROR')
            : ''
        "
        @blur="$v.credentials.accountName.$touch"
      />
      <woot-input
        v-model.trim="credentials.password"
        type="password"
        :class="{ error: $v.credentials.password.$error }"
        :label="$t('LOGIN.PASSWORD.LABEL')"
        :placeholder="$t('SET_NEW_PASSWORD.PASSWORD.PLACEHOLDER')"
        :error="passwordErrorText"
        @blur="$v.credentials.password.$touch"
      />
      <woot-submit-button
        :disabled="isSignupInProgress"
        button-text="Next"
        :loading="isSignupInProgress"
        button-class="large expanded"
      />
      <p v-dompurify-html="termsLink" class="accept--terms" />
    </form>
  </div>
</template>
<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import alertMixin from 'shared/mixins/alertMixin';
import { isValidPassword } from 'shared/helpers/Validators';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';

export default {
  mixins: [globalConfigMixin, alertMixin],
  props: {
    isSignupInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      credentials: {
        accountName: '',
        fullName: '',
        email: '',
        password: '',
        phoneNumber: '',
      },
      error: '',
    };
  },
  validations: {
    credentials: {
      accountName: {
        required,
        minLength: minLength(2),
      },
      fullName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        isValidPassword,
        minLength: minLength(6),
      },
      phoneNumber: {
        required,
        isPhoneE164OrEmpty,
      },
    },
  },
  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
    termsLink() {
      return this.$t('REGISTER.TERMS_ACCEPT');
      // .replace('https://www.chatwoot.com/terms', this.globalConfig.termsURL)
      // .replace(
      //   'https://www.chatwoot.com/privacy-policy',
      //   this.globalConfig.privacyURL
      // );
    },
    passwordErrorText() {
      const { password } = this.$v.credentials;
      if (!password.$error) {
        return '';
      }
      if (!password.minLength) {
        return this.$t('REGISTER.PASSWORD.ERROR');
      }
      if (!password.isValidPassword) {
        return this.$t('REGISTER.PASSWORD.IS_INVALID_PASSWORD');
      }
      return '';
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit('submit', this.credentials);
      //   this.isSignupInProgress = true;
      //   try {
      //     const response = await Auth.register(this.credentials);
      //     if (response.status === 200) {
      //       window.location = DEFAULT_REDIRECT_URL + 'accounts';
      //     }
      //   } catch (error) {
      //     let errorMessage = this.$t('REGISTER.API.ERROR_MESSAGE');
      //     if (error.response && error.response.data.message) {
      //       errorMessage = error.response.data.message;
      //     }
      //     this.showAlert(errorMessage);
      //   } finally {
      //     this.isSignupInProgress = false;
      //   }
    },
  },
};
</script>
<style scoped lang="scss">
.signup {
  .signup--hero {
    margin-bottom: var(--space-larger);

    .hero--logo {
      width: 180px;
    }

    .hero--title {
      margin-top: var(--space-default);
      font-weight: var(--font-weight-light);
    }
  }

  .signup--box {
    padding: var(--space-large);

    label {
      font-size: var(--font-size-default);
      color: var(--b-600);

      input {
        padding: var(--space-slab);
        height: var(--space-larger);
        font-size: var(--font-size-default);
      }
    }
  }

  .sigin--footer {
    padding: var(--space-medium);
    font-size: var(--font-size-default);

    > a {
      font-weight: var(--font-weight-bold);
    }
  }

  .accept--terms {
    font-size: var(--font-size-small);
    text-align: center;
    margin: var(--space-normal) 0 0 0;
  }

  .h-captcha--box {
    margin-bottom: var(--space-one);

    .captcha-error {
      color: var(--r-400);
      font-size: var(--font-size-small);
    }

    &::v-deep .error {
      iframe {
        border: 1px solid var(--r-500);
        border-radius: var(--border-radius-normal);
      }
    }
  }
}
</style>
