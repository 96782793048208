<template>
  <div class="conv-header">
    <div class="user">
      <Thumbnail size="40px" username="+" />
      <p class="to-tag">To:</p>
      <div class="multi-select-option">
        <span class="area-code"> +1 </span>
        <multiselect
          v-model="phoneNumber"
          :options="contactsList"
          class="multi-select-dropdown"
          track-by="id"
          label="mergeValue"
          :multiple="true"
          :taggable="contactsList.length ? false : true"
          :max="1"
          :searchable="true"
          :loading="isLoading"
          :internal-search="false"
          :close-on-select="true"
          :clear-on-select="false"
          :hide-selected="true"
          :placeholder="$t('CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          @open="openDropdown"
          @tag="addTag"
          @search-change="findContact"
          @close="getAllContacts"
          @remove="removePhoneNumber"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import agentMixin from '../../../mixins/agentMixin.js';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import inboxMixin from 'shared/mixins/inboxMixin';
// import InboxName from '../InboxName';
import Thumbnail from '../Thumbnail';
import { debounce } from '@chatwoot/utils';
// import { isPhoneE164 } from 'shared/helpers/Validators';

// register globally
export default {
  components: {
    // InboxName,
    Thumbnail,
  },
  mixins: [inboxMixin, agentMixin, eventListenerMixins],
  props: {
    chat: {
      type: Object,
      default: () => {},
    },
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneNumber: [],
      selected: null,
      options: ['list', 'of', 'options'],
      isLoading: false,
      searchQuery: '',
      searchContact: () => {},
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      currentChat: 'getSelectedChat',
      agentList: 'agents/getAgents',
      contacts: 'contacts/getSearchContacts',
      newRecord: 'contacts/getNewContact',
    }),
    contactsList() {
      let contacts = Object.values(this.contacts);
      contacts.map(value => {
        value.mergeValue = `${value.name} - ${value.phone_number}`;
        return value;
      });
      return contacts;
    },
    inbox() {
      const { inbox_id: inboxId } = this.chat;
      return this.$store.getters['inboxes/getInbox'](inboxId);
    },
  },
  validations: {
    // phoneNumber: {
    //   isPhoneE164,
    // },
  },
  mounted() {
    if (this.$route.query.conversation_number) {
      this.fetchContactsForMountedHook(this.$route.query.conversation_number);
    }
    this.$store.dispatch('contacts/clearSearch');
    let self = this;
    this.searchContact = debounce(
      () => {
        self.fetchContacts();
      },
      500,
      false
    );
  },
  methods: {
    removePhoneNumber() {
      this.$store.dispatch('contacts/setNewContact', null);
      this.pushToNewConversationRoute();
    },
    openDropdown() {
      this.$store.dispatch('contacts/clearSearch');
    },
    pushToNewConversationRoute() {
      if (this.$route.name !== 'new_conversation')
        this.$router.push({
          name: 'new_conversation',
          params: {
            inbox_id: this.$route.params.inbox_id,
            accountId: this.$route.params.accountId,
          },
        });
    },
    addTag(phoneNumber) {
      phoneNumber = phoneNumber.replace(/[^a-zA-Z0-9+]/g, '');
      if (phoneNumber.slice(0, 2) !== '+1') {
        if (phoneNumber.charAt(0) === '+') {
          phoneNumber = phoneNumber.substring(1);
        }
        phoneNumber = `+1${phoneNumber}`;
      }
      // if (this.searchQuery.str.slice(0, 2))
      const tag = {
        phone_number: phoneNumber,
        name: 'No name',
        mergeValue: `No name - ${phoneNumber}`,
      };
      this.phoneNumber.push(tag);
    },
    findContact(query) {
      this.searchQuery = query.replace(/[^a-zA-Z0-9+]/g, '');
      if (this.searchQuery.length < 3) {
        return;
      }
      this.isLoading = true;
      this.searchContact();
    },

    async getAllContacts() {
      let contactData = this.phoneNumber[0];
      if (this.phoneNumber.length) {
        this.$store.dispatch('contacts/setNewContact', contactData);
      }
      if (contactData.id) {
        const res = await this.$store.dispatch('getUserConversationsById', {
          inboxId: this.$route.params.inbox_id,
          userId: contactData.id,
        });
        this.$router.push({
          name: 'new_conversation_through_inbox',
          params: {
            conversation_id: res.id,
            inbox_id: this.$route.params.inbox_id, // USED toString() METHOD TO AVOID UN NECESSARY CONDITIONAL RENDERING WHICH IS OCCUR DUE TO TYPE
            accountId: this.$route.params.accountId,
          },
        });
      } else {
        this.pushToNewConversationRoute();
      }
      this.searchQuery = '';
      this.fetchContacts();
    },

    fetchContacts() {
      let value = '';
      if (this.searchQuery.charAt(0) === '+') {
        value = this.searchQuery.substring(1);
      } else {
        value = this.searchQuery;
      }
      // console.log(value);
      const requestParams = {
        sortAttr: 'name',
      };
      if (!value) {
        // this.$store.dispatch('contacts/get', requestParams);
        this.isLoading = false;
      } else {
        this.$store
          .dispatch('contacts/newsearch', {
            search: encodeURIComponent(value),
            ...requestParams,
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },

    fetchContactsForMountedHook(searchQuery) {
      let value = '';
      if (searchQuery.charAt(0) === '+') {
        value = searchQuery.substring(1);
      } else {
        value = searchQuery;
      }
      // console.log(value);
      const requestParams = {
        sortAttr: 'name',
      };
      if (!value) {
        // this.$store.dispatch('contacts/get', requestParams);
        this.isLoading = false;
      } else {
        this.$store
          .dispatch('contacts/newsearch', {
            search: encodeURIComponent(value),
            ...requestParams,
          })
          .then(() => {
            const contacts = this.contactsList.filter(contact => {
              // Skipping +1 every time
              let ph =
                contact.phone_number.charAt(0) === '+'
                  ? contact.phone_number.substring(2)
                  : contact.phone_number;
              return ph === value;
            });
            let contactData = {
              name: 'No name',
              phone_number: this.$route.query.conversation_number,
              mergeValue: `No name - ${this.$route.query.conversation_number}`,
            };
            if (contacts && contacts.length) {
              contactData = contacts[0];
            }

            this.phoneNumber = [contactData];
            this.$store.dispatch('contacts/setNewContact', contactData);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conv-header {
  flex: 0 0 var(--space-jumbo);
}

.option__desc {
  display: flex;
  align-items: center;
}

.option__desc {
  &::v-deep .status-badge {
    margin-right: var(--space-small);
    min-width: 0;
    flex-shrink: 0;
  }
}

.user--name {
  display: inline-block;
  font-size: var(--font-size-medium);
  line-height: 1.3;
  margin: 0;
  text-transform: capitalize;
  width: 100%;
}

.conversation--header--actions {
  align-items: center;
  display: flex;
  font-size: var(--font-size-mini);

  .user--profile__button {
    padding: 0;
  }

  .snoozed--display-text {
    font-weight: var(--font-weight-medium);
    color: var(--y-600);
  }
}

.hmac-warning__icon {
  color: var(--y-600);
}
.to-tag {
  margin: 0 10px;
  font-size: 1.7rem;
}
.add-contact {
  margin-bottom: 0;
  width: 400px;
}
.multi-select-option {
  width: 400px;
  display: flex;
}
.area-code {
  border-color: var(--s-200);
  min-height: 4.4rem;
  padding-top: 0;
  display: block;
  padding: 11px 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--s-400);
  border-radius: 5px 0 0 5px;
  border: 1px solid var(--s-200);
  background: #fff;
  border-right: 0;
}
</style>
<style>
.multiselect {
  margin: 0;
}
.multi-select-dropdown > .multiselect__tags {
  border-radius: 0 5px 5px 0 !important;
  border-left: 0 !important;
}
.multi-select-dropdown > .multiselect__content-wrapper {
  left: -25px !important;
  width: 106% !important;
}
</style>

<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
