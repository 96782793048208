<template>
  <div class="columns top-space-medium">
    <div class="current-plan--details">
      <h6>CURRENT PLAN</h6>
    </div>
    <div v-if="hasPlan && currentPackage" class="add-plan-div row">
      <div class="small-12 medium-6 column">
        <h3 class="page-sub-title plan-heading">
          {{ currentPackage.title }}
        </h3>
        <p class="sub-block-title">
          ${{ currentPackage.price_month }} per month
        </p>
        <!-- <p class="text-block-title">
          Your plan renews on
          {{ timeFormat(currentPackage.updated_at) }}.
        </p> -->
      </div>
    </div>
    <div v-else class="add-plan-div">
      <p class="grey-text">No Plan Selected.</p>
      <div class="column btn-width">
        <router-link
          :to="frontendURL(`accounts/${accountId}/billing/portal/plans/list`)"
        >
          <woot-button :is-expanded="true">
            Add Plan
          </woot-button>
        </router-link>
      </div>
    </div>
    <div v-if="current_package.package_details">
      <div class="current-plan--details top-space-larger">
        <h6>PAYMENT METHOD</h6>
      </div>
      <div v-if="hasCard" class="add-plan-div">
        <h5 class="sub-block-title plan-heading">
          <img class="card-width" :src="cardType[card.brand]" alt="visa" />
          **** **** **** {{ card.last4 }}
        </h5>
        <p class="text-block-title">
          Expires on {{ card.exp_month }}, {{ card.exp_year }}.
        </p>
        <router-link
          :to="
            frontendURL(`accounts/${accountId}/billing/portal/payment-method`)
          "
        >
          <h5 class="grey-text-bold">
            <img src="~dashboard/assets/images/edit-icon.svg" alt="plus" />
            Update information
          </h5>
        </router-link>
      </div>
      <div v-else class="add-plan-div">
        <p class="grey-text">No payment method.</p>
        <router-link
          :to="
            frontendURL(`accounts/${accountId}/billing/portal/payment-method`)
          "
        >
          <h5 class="grey-text-bold">
            <img src="~dashboard/assets/images/plus_Vector.svg" alt="plus" />
            Add payment method
          </h5>
        </router-link>
      </div>
    </div>
    <div class="current-plan--details top-space-larger">
      <h6>INVOICE HISTORY</h6>
    </div>
    <div v-if="hasPayments && payments.length" class="agent-table-container">
      <ve-table
        max-height="calc(100vh - 35rem)"
        :fixed-header="true"
        :columns="columns"
        :table-data="payments"
      />
    </div>
    <div v-else class="add-plan-div">
      <p class="grey-text">No payment history.</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { frontendURL } from '../../../../helper/URLHelper';
import visa from '../../../../assets/images/payment-icons/visa.png';
import checkout from '../../../../assets/images/payment-icons/2checkout.png';
import americanExpress from '../../../../assets/images/payment-icons/american-express.png';
import cirrus from '../../../../assets/images/payment-icons/cirrus.png';
import delta from '../../../../assets/images/payment-icons/delta.png';
import directDebit from '../../../../assets/images/payment-icons/direct-debit.png';
import discover from '../../../../assets/images/payment-icons/discover.png';
import ebay from '../../../../assets/images/payment-icons/ebay.png';
import mastercard from '../../../../assets/images/payment-icons/mastercard.png';
import { VeTable } from 'vue-easytable';
import timeAgo from 'dashboard/components/ui/TimeAgo';
import { VeIcon } from 'vue-easytable';
export default {
  components: {
    VeTable,
    // eslint-disable-next-line vue/no-unused-components
    VeIcon,
    // eslint-disable-next-line vue/no-unused-components
    timeAgo,
  },
  data() {
    return {
      hasPlan: false,
      hasCard: false,
      hasPayments: false,
      cardType: {
        visa: visa,
        checkout: checkout,
        american_express: americanExpress,
        cirrus: cirrus,
        delta: delta,
        directDebit: directDebit,
        discover: discover,
        ebay: ebay,
        mastercard: mastercard,
      },
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      card: 'billing/getCards',
      current_package: 'billing/getCurrentPackage',
      payments: 'billing/getPayments',
    }),
    columns() {
      return [
        {
          field: 'customer_name',
          key: 'customer_name',
          title: 'Name',
          fixed: 'left',
          align: 'left',
          width: 10,
        },
        {
          field: 'customer_email',
          key: 'customer_email',
          title: 'Email',
          // fixed: 'left',
          align: 'left',
          width: 10,
        },
        {
          field: 'amount_paid',
          key: 'amount_paid',
          title: 'Amount',
          align: 'left',
          width: 10,
        },
        {
          field: 'status',
          key: 'status',
          title: 'Status',
          align: 'left',
          width: 10,
          renderBodyCell: ({ row }) => {
            return (
              <span
                class={row.status === 'paid' ? 'live success' : 'live danger'}
              >
                <span
                  class={
                    row.status === 'paid' ? 'ellipse success' : 'ellipse danger'
                  }
                />
                <span>{row.status}</span>
              </span>
            );
          },
        },
        {
          field: 'created_at',
          key: 'created_at',
          title: 'Created at',
          align: 'left',
          width: 10,
          renderBodyCell: ({ row }) => {
            return <time-ago timestamp={row.created_at} />;
          },
        },
        {
          field: 'invoice_url',
          key: 'invoice_url',
          title: 'Invoice',
          align: 'left',
          width: 10,
          renderBodyCell: ({ row }) => {
            return (
              <span>
                <button
                  class="button-demo download-btn"
                  on-click={() => this.downloadInvoice(row.invoice_url)}
                >
                  <fluent-icon icon="arrow-download" />
                </button>
              </span>
            );
          },
        },
      ];
    },
    currentPackage() {
      return this.current_package.package_details || null;
    },
  },
  mounted() {
    this.getCurrentPackage();
    this.getCards();
    this.getPaymentsHistory();
    this.$store.dispatch('isSubscribed');
  },
  methods: {
    frontendURL,
    clickAddPlan() {},
    getCards() {
      this.$store.dispatch('billing/getCard').then(() => {
        if (this.card) {
          this.hasCard = true;
        }
      });
    },
    getCurrentPackage() {
      let id = this.accountId;
      this.$store.dispatch('billing/getCurrentPackage', { id }).then(() => {
        if (this.current_package) {
          this.hasPlan = true;
        }
      });
    },
    getPaymentsHistory() {
      this.$store.dispatch('billing/getPaymentsHistory').then(() => {
        if (this.payments.length) {
          this.hasPayments = true;
        }
      });
    },
    downloadInvoice(url) {
      window.location.assign(url);
    },
  },
};
</script>
<style scoped lang="scss">
.top-space-medium {
  margin-top: var(--space-medium);
}
.top-space-larger {
  margin-top: var(--space-larger);
}
.grey-text {
  color: #697386;
  font-size: 14px;
}
.grey-text-bold {
  color: #697386;
  font-size: 14px;
  font-weight: 600;
  margin-top: var(--space-normal);
}
.btn-width {
  margin: 15px 0;
  min-width: auto;
  max-width: 233px;
}
.plan-heading {
  font-weight: 700;
}
.align-right {
  margin-left: auto;
}
.card-width {
  height: 3rem;
  margin-bottom: 1rem;
}
.agent-table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    &::v-deep {
      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-small) var(--space-two) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-one) var(--space-two) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .row-user-block {
    align-items: center;
    display: flex;
    text-align: left;

    .user-block {
      display: flex;
      flex-direction: column;
      min-width: 0;
      .title {
        font-size: var(--font-size-small);
        margin: var(--zero);
        line-height: 1;
      }
      .sub-title {
        font-size: var(--font-size-mini);
      }
    }

    .user-thumbnail-box {
      margin-right: var(--space-small);
    }
  }

  .table-pagination {
    margin-top: var(--space-normal);
    text-align: right;
  }
}
.success {
  background: rgba(37, 211, 102, 0.1);
  color: var(--g-400);
  .ellipse {
    background-color: var(--g-400) !important;
  }
}
.danger {
  background: rgba(209, 98, 47, 0.1);
  color: var(--r-400);
  .ellipse {
    background-color: var(--r-400) !important;
  }
}
.live {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: var(--space-small);
  padding-left: var(--space-small);
  margin: var(--space-smaller);
  font-size: var(--font-size-mini);
  width: 60px;

  .ellipse {
    height: var(--space-smaller);
    width: var(--space-smaller);
    border-radius: var(--border-radius-rounded);
    margin-right: var(--space-smaller);
  }
}
.download-btn {
  cursor: pointer !important;
}
</style>
