/* global axios */
// import ApiClient from './ApiClient';

class Ezyagent {
  // eslint-disable-next-line class-methods-use-this
  create(data) {
    return axios.post(`https://api.ezyagent.com/api/v1/create_contact`, data);
  }

  // eslint-disable-next-line class-methods-use-this
  subscribed(email) {
    return axios.post(`https://api.ezyagent.com/api/v1/payment_status`, {
      email,
    });
  }
}

export default new Ezyagent();
