import { render, staticRenderFns } from "./KeywordTable.vue?vue&type=template&id=785796b6&scoped=true&"
import script from "./KeywordTable.vue?vue&type=script&lang=js&"
export * from "./KeywordTable.vue?vue&type=script&lang=js&"
import style0 from "./KeywordTable.vue?vue&type=style&index=0&id=785796b6&lang=scss&scoped=true&"
import style1 from "./KeywordTable.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "785796b6",
  null
  
)

export default component.exports