<template>
  <div>
    <h5 class="hero--title">
      Enter Verification code
    </h5>
    <form class="signup--box login-box" @submit.prevent="submit">
      <otp-input
        :is-valid="isCodeValid"
        :digits="6"
        class="otp-box"
        @on-complete="onCompleteHandler"
      >
        <template #errorMessage> Please Enter a valid code </template>
      </otp-input>
      <woot-submit-button
        :disabled="isSignupInProgress || submitDisabled"
        button-text="Submit"
        :loading="isSignupInProgress"
        button-class="large expanded"
      />
    </form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import OtpInput from 'otp-input-vue2';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  components: { OtpInput },
  mixins: [alertMixin],
  props: {
    isSignupInProgress: {
      type: Boolean,
      default: false,
    },
    isCodeValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      code: '',
      error: '',
      submitDisabled: true,
    };
  },
  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
  },
  methods: {
    async submit() {
      this.$emit('submit', this.code);
    },
    onCompleteHandler(code) {
      this.submitDisabled = false;
      this.code = code;
    },
  },
};
</script>
<style scoped lang="scss">
.signup {
  .signup--box {
    padding: var(--space-large);

    label {
      font-size: var(--font-size-default);
      color: var(--b-600);

      input {
        padding: var(--space-slab);
        height: var(--space-larger);
        font-size: var(--font-size-default);
      }
    }
  }
}
.otp-box {
  margin: 15px auto !important;
  text-align: center;
}
</style>
<style>
div.vue-otp-input > div.otp-wrapper > input.otp-input {
  width: 40px !important;
  text-align: center !important;
}
</style>
