<template>
  <div class="column content-box">
    <div class="row">
      <metric-card
        :header="this.$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.HEADER')"
      >
        <schedule-message-table
          :page-index="pageIndex"
          :is-loading="uiFlags.isFetching"
          @edit-schedule="openEditPopup"
        />
      </metric-card>
    </div>

    <!-- Edit Canned Response -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-schedule-message
        v-if="showEditPopup"
        :id="selectedResponse.id"
        :edschedule="selectedResponse.schedule_date"
        :edcontent="selectedResponse.content"
        :on-close="hideEditPopup"
        @updateSchedule="updateSchedule"
        @deleteSchedule="deleteSchedule(selectedResponse.id)"
      />
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import EditScheduleMessage from './EditScheduleMessage';
import ScheduleMessageTable from './components/ScheduleMessageTable.vue';
import MetricCard from './components/MetricCard';

export default {
  components: {
    EditScheduleMessage,
    ScheduleMessageTable,
    MetricCard,
  },
  data() {
    return {
      loading: {},
      pageIndex: 1,
      showEditPopup: false,
      selectedResponse: {},
      cannedResponseAPI: {
        message: '',
      },
      keywordMetric: [],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'scheduleMessages/getUIFlags',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('SCHEDULE_MESSAGE_SETTINGS.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.keyword
      }`;
    },
    deleteRejectText() {
      return `${this.$t('SCHEDULE_MESSAGE_SETTINGS.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.keyword
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedResponse.keyword}?`;
    },
  },
  mounted() {
    // Fetch API Call
    this.$store.dispatch('scheduleMessages/get');
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.selectedResponse.id] = false;
      this.selectedResponse = {};
      // Show message
      this.cannedResponseAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    // Edit Modal Functions
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedResponse = response;
    },
    updateSchedule() {
      this.$store.dispatch('scheduleMessages/get');
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    async deleteSchedule(id) {
      const messageId = id;
      await this.$store
        .dispatch('scheduleMessages/delete', {
          messageId,
        })
        .then(() => {
          this.showAlert('Message schedule deleted successfully.');
          this.updateSchedule();
          // this.data.schedule_date = null;
          // this.data.scheduled = false;
          // this.$store.dispatch('updateMessage', this.data);
        })
        .catch(() => {
          this.showAlert('Unable to delete message schedule');
        });
      this.hideEditPopup();
    },
  },
};
</script>
<style scoped>
.short-code {
  width: 14rem;
}
</style>
