<template>
  <div class="labels--select">
    <!-- <small class="labels--heading">Labels</small> -->
    <select
      v-model="activeStatus"
      class="status--filter label--filter"
      @change="onTabChange()"
    >
      <option value="all">
        {{ $t('CHAT_LIST.FILTER_ALL') }}
      </option>
      <option v-for="(value, id) in labels" :key="id" :value="value.title">
        <span
          v-if="value.color"
          class="badge--label"
          :style="{ backgroundColor: value.color }"
        />
        {{ value['title'] }}
        <!-- <secondary-child-nav-item
        :key="value.id"
        :to="child.toState"
        :label="value.title"
        :label-color="value.color"
        :should-truncate="true"
      /> -->
      </option>
    </select>
  </div>
</template>

<script>
import wootConstants from '../../../constants';
// import SecondaryChildNavItem from '../../layout/sidebarComponents/SecondaryChildNavItem.vue';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import { hasPressedAltAndBKey } from 'shared/helpers/KeyboardHelpers';
import { frontendURL } from '../../../helper/URLHelper';
import { mapGetters } from 'vuex';

export default {
  // components: { SecondaryChildNavItem },
  mixins: [eventListenerMixins],
  data: () => ({
    activeStatus: 'all',
  }),
  computed: {
    ...mapGetters({
      labels: 'labels/getLabelsOnSidebar',
      accountId: 'getCurrentAccountId',
    }),
  },
  mounted() {
    if (this.$route.name !== 'home') {
      this.$router.push(frontendURL(`accounts/${this.accountId}/dashboard`));
    }
  },
  methods: {
    getUrl(label) {
      return frontendURL(`accounts/${this.accountId}/label/${label.title}`);
    },
    handleKeyEvents(e) {
      if (hasPressedAltAndBKey(e)) {
        if (this.activeStatus === wootConstants.STATUS_TYPE.OPEN) {
          this.activeStatus = wootConstants.STATUS_TYPE.RESOLVED;
        } else if (this.activeStatus === wootConstants.STATUS_TYPE.RESOLVED) {
          this.activeStatus = wootConstants.STATUS_TYPE.PENDING;
        } else if (this.activeStatus === wootConstants.STATUS_TYPE.PENDING) {
          this.activeStatus = wootConstants.STATUS_TYPE.SNOOZED;
        } else if (this.activeStatus === wootConstants.STATUS_TYPE.SNOOZED) {
          this.activeStatus = wootConstants.STATUS_TYPE.ALL;
        } else if (this.activeStatus === wootConstants.STATUS_TYPE.ALL) {
          this.activeStatus = wootConstants.STATUS_TYPE.OPEN;
        }
      }
      this.onTabChange();
    },
    onTabChange() {
      if (this.activeStatus === 'all') {
        this.$router.push(frontendURL(`accounts/${this.accountId}/dashboard`));
      } else {
        this.$router.push(
          frontendURL(`accounts/${this.accountId}/label/${this.activeStatus}`)
        );
      }
      // this.$store.dispatch('setChatFilter', this.activeStatus);
      this.$emit('labelFilterChange', this.activeStatus);
    },
  },
};
</script>
<style lang="scss" scoped>
$badge-size: var(--space-normal);
$label-badge-size: var(--space-slab);
.badge--label {
  display: inline-flex;
  border-radius: var(--border-radius-small);
  margin-right: var(--space-smaller);
  background: var(--s-100);
}

.badge--label {
  height: $label-badge-size;
  min-width: $label-badge-size;
  margin-left: var(--space-smaller);
  border: 1px solid var(--color-border-light);
}

.label--filter {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.labels--select {
  display: flex;
  flex-direction: column;
}
.labels--heading {
  line-height: var(--space-smaller);
  margin: var(--space-smaller) 0;
  padding: 0 var(--space-small);
}
</style>
