<template>
  <div class="audio-wave-wrapper">
    <audio-recorder
      v-if="!srcUrl"
      :pause-recording="callback"
      :mic-failed="callback"
      :after-recording="afterRecording"
    />
    <audio-player v-if="srcUrl" class="player" :src="srcUrl" />
  </div>
</template>
<script>
import AudioRecorder from 'vue-audio-recorder';
import Vue from 'vue';
Vue.use(AudioRecorder);
export default {
  data() {
    return {
      srcUrl: '',
    };
  },
  methods: {
    callback(data) {
      // eslint-disable-next-line no-console
      console.log(data);
    },
    afterRecording(data) {
      this.srcUrl = data.url;
      const file = new File([data.blob], data.id, {
        type: data.blob.type,
      });
      this.fireRecorderBlob(file);
    },
    fireRecorderBlob(blob) {
      this.$emit('finish-record', {
        name: blob.name,
        type: blob.type,
        size: blob.size,
        file: blob,
      });
    },
    fireStateRecorderChanged(state) {
      this.$emit('state-recorder-changed', state);
    },
    fireProgressRecord(duration) {
      this.$emit('state-recorder-progress-changed', duration);
    },
  },
};
</script>
<style lang="scss">
.audio-wave-wrapper {
  min-height: 8rem;
  height: 8rem;
}
</style>
<style>
.ar-content {
  padding: 0 !important;
  display: flex;
  flex-direction: unset !important;
  align-items: center;
  height: 75px;
}
.ar {
  width: 420px;
  font-family: 'Roboto', sans-serif;
  border-radius: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
  position: relative;
  /* box-sizing: content-box; */
}
.ar-recorder__records-limit {
  display: none !important;
}
.ar-recorder__duration {
  color: #aeaeae;
  font-size: 12px !important;
  font-weight: 500;
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  margin-left: 68px;
  margin-right: 10px;
}
.audio-wave-wrapper {
  align-items: center !important;
  display: flex !important;
}
</style>
