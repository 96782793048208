<template>
  <div class="text-center voice-note">
    <audio v-if="voiceNote" controls>
      <source :src="voiceNote.audio_url" />
    </audio>
    <small class="powered-by">@powered by EzyAgent</small>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    id: { type: String, default: '' },
  },
  computed: {
    ...mapGetters({
      voiceNote: 'voice/getVoiceNote',
    }),
  },
  mounted() {
    this.$store.dispatch('voice/fetchUrl', this.id);
  },
};
</script>
<style scoped>
.voice-note {
  display: grid;
}
.powered-by {
  margin-top: -20px;
}
</style>
