<template>
  <div class="row portal-height">
    <div class="medium-3 small-12 columns billing--settings hide-on-mobile">
      <h1 class="block-title logo">
        <img :src="globalConfig.logoThumbnail" alt="logo" />
        EzyText
      </h1>
      <h1 class="page-sub-title sub-heading">
        EzyText partners with Stripe for simplified billing.
      </h1>
    </div>
    <div class="medium-9 small-12 columns form-page billing--settings">
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';
$logo-size: 32px;

.logo {
  padding: var(--space-normal);

  img {
    width: $logo-size;
    height: $logo-size;
    object-fit: cover;
    object-position: left center;
  }
}
.billing--settings {
  height: calc(100vh - 5.6rem);
  padding: 20px;
  overflow: auto;
}

.billing--settings--row {
  @include border-normal-bottom;
  padding: $space-normal;
}
.portal-height {
  height: 100%;
}
.form-page {
  background-color: #fff;
}
.sub-heading {
  margin: 70px 16px 0;
  max-width: 268px;
}
.hide-on-mobile {
  display: block;
  @media screen and (max-width: 639px) {
    display: none;
  }
}
</style>
