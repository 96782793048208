/* eslint-disable no-unused-vars */
import twilio from '../../api/twilio';

// actions
export const actions = {
  searchNumbers: ({ commit }, params) => {
    // Do something here... lets say, a http call using vue-resource
    return twilio
      .searchNumbers(params.areaCode, params.accountId)
      .then(response => {
        // http success, call the mutator and change something in state
        return response;
      });
  },

  smsChannelNotPresent: ({ commit }, params) => {
    return twilio.smsChannelNotPresent(params.accountId).then(response => {
      return response;
    });
  },
};

export default {
  actions,
};
