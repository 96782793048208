/* global axios */
import ApiClient from './ApiClient';

export const buildCreatePayload = ({
  inbox_id,
  source_id,
  additional_attributes,
  contact_id,
  message: isMessage,
  assignee_id,
}) => {
  let payload;
  if (isMessage.attachments && isMessage.attachments.length !== 0) {
    payload = new FormData();
    // let message = JSON.stringify(isMessage);
    payload.append('inbox_id', inbox_id);
    payload.append('source_id', source_id);
    payload.append('contact_id', contact_id);
    payload.append('assignee_id', assignee_id);
    payload.append(
      'additional_attributes',
      JSON.stringify(additional_attributes)
    );
    if (isMessage.content) {
      payload.append('message[content]', isMessage.content);
    }
    isMessage.attachments.forEach(file => {
      payload.append('attachments[]', file);
    });
    if (isMessage.schedule_time) {
      payload.append('message[scheduled]', true);
      payload.append('message[schedule_time]', isMessage.schedule_time);
    }
    payload.append('message[private]', isMessage.private);
    payload.append('message[cc_emails]', isMessage.ccEmails);
    payload.append('message[bcc_emails]', isMessage.bccEmails);
    payload.append('conversation_type', 'new');
    payload.append('is_attachment', true);
  } else {
    payload = {
      inbox_id: inbox_id,
      source_id: source_id,
      contact_id: contact_id,
      message: isMessage,
      assignee_id: assignee_id,
      additional_attributes: additional_attributes,
      attachments: [],
      conversation_type: 'new',
      is_attachment: false,
    };
    if (isMessage.schedule_time) {
      payload.message.scheduled = true;
      payload.message.schedule_time = isMessage.schedule_time;
    }
  }
  return payload;
};

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  create(data) {
    data = buildCreatePayload(data);
    return axios({
      method: 'post',
      url: `${this.url}`,
      data,
    });
  }

  getLabels(conversationID) {
    return axios.get(`${this.url}/${conversationID}/labels`);
  }

  updateLabels(conversationID, labels) {
    return axios.post(`${this.url}/${conversationID}/labels`, { labels });
  }

  deleteConversation(accountID, ids) {
    return axios.post(`${this.url}/destroy_chat`, { ids });
  }

  importChats(file) {
    const formData = new FormData();
    formData.append('import_file', file);
    return axios.post(`${this.url}/import_chat`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export default new ConversationApi();
