<template>
  <div>
    <div v-if="signatureEnabled" class="signature-box">
      <emoji-or-icon class="icon margin-r" icon="signature" :icon-size="10" />
      {{ signature.replace(/\\/g, '') }}
    </div>
    <div class="bottom-box" :class="wrapClass">
      <div class="left-wrap">
        <woot-button
          v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
          :title="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
          icon="emoji"
          emoji="😊"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          @click="toggleEmojiPicker"
        />
        <file-upload
          ref="upload"
          v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
          :size="4096 * 4096"
          :accept="allowedFileTypes"
          :multiple="enableMultipleFileUpload"
          :drop="true"
          :drop-directory="false"
          :data="{
            direct_upload_url: '/rails/active_storage/direct_uploads',
            direct_upload: true,
          }"
          @input-file="onFileUpload"
        >
          <woot-button
            v-if="showAttachButton"
            class-names="button--upload"
            :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
            icon="attach"
            emoji="📎"
            color-scheme="secondary"
            variant="smooth"
            size="small"
          />
        </file-upload>
        <woot-button
          v-if="showAudioRecorderButton"
          v-tooltip.top-end="'Record Voicenote'"
          :icon="!isRecordingAudio ? 'microphone' : 'microphone-off'"
          emoji="🎤"
          :color-scheme="!isRecordingAudio ? 'secondary' : 'alert'"
          variant="smooth"
          size="small"
          :title="$t('CONVERSATION.REPLYBOX.TIP_AUDIORECORDER_ICON')"
          @click="toggleAudioRecorder"
        />
        <woot-button
          v-if="showEditorToggle"
          v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_FORMAT_ICON')"
          icon="quote"
          emoji="🖊️"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          @click="$emit('toggle-editor')"
        />
        <!-- <woot-button
          v-if="showAudioPlayStopButton"
          :icon="audioRecorderPlayStopIcon"
          emoji="🎤"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          @click="toggleAudioRecorderPlayPause"
        >
          <span>{{ recordingAudioDurationText }}</span>
        </woot-button> -->
        <woot-button
          v-tooltip.top-end="signatureToggleTooltip"
          icon="signature"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          :title="signatureToggleTooltip"
          @click="toggleMessageSignature"
        />
        <woot-button
          v-if="hasWhatsappTemplates"
          v-tooltip.top-end="'Whatsapp Templates'"
          icon="whatsapp"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          :title="'Whatsapp Templates'"
          @click="$emit('selectWhatsappTemplate')"
        />
        <transition name="modal-fade">
          <div
            v-show="$refs.upload && $refs.upload.dropActive"
            class="modal-mask"
          >
            <fluent-icon icon="cloud-backup" />
            <h4 class="page-sub-title">
              {{ $t('CONVERSATION.REPLYBOX.DRAG_DROP') }}
            </h4>
          </div>
        </transition>
        <woot-button
          v-if="showSchedule"
          v-tooltip.top-end="'Schedule message'"
          icon="calendar-clock"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          :title="'Schedule'"
          @click="enableSchedule"
        />
        <input
          v-if="show_schedule"
          id="date_picker"
          v-model="date_time"
          v-tooltip.top-end="'Schedule message'"
          type="datetime-local"
        />
        <woot-button
          v-if="reviewCannedMessage"
          v-tooltip.top-end="'Add Google Review Link'"
          icon="google"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          :title="'Schedule'"
          @click="addCannedMessage"
        />
      </div>
      <div class="right-wrap">
        <woot-button
          size="small"
          :class-names="buttonClass"
          :is-disabled="isSendDisabled"
          @click="SendMessage"
        >
          {{ sendButtonText }}
        </woot-button>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
import * as ActiveStorage from 'activestorage';
import { hasPressedAltAndAKey } from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import inboxMixin from 'shared/mixins/inboxMixin';
import { FEATURE_FLAGS } from 'dashboard/featureFlags';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_FILE_TYPES_FOR_TWILIO_WHATSAPP,
} from 'shared/constants/messages';
import EmojiOrIcon from 'shared/components/EmojiOrIcon';
import { REPLY_EDITOR_MODES } from './constants';
import { mapGetters } from 'vuex';

export default {
  name: 'ReplyBottomPanel',
  components: { FileUpload, EmojiOrIcon },
  mixins: [eventListenerMixins, uiSettingsMixin, inboxMixin],
  props: {
    mode: {
      type: String,
      default: REPLY_EDITOR_MODES.REPLY,
    },
    onSend: {
      type: Function,
      default: () => {},
    },
    sendButtonText: {
      type: String,
      default: '',
    },
    recordingAudioDurationText: {
      type: String,
      default: '',
    },
    inbox: {
      type: Object,
      default: () => ({}),
    },
    showFileUpload: {
      type: Boolean,
      default: false,
    },
    showAudioRecorder: {
      type: Boolean,
      default: false,
    },
    onFileUpload: {
      type: Function,
      default: () => {},
    },
    showEmojiPicker: {
      type: Boolean,
      default: false,
    },
    toggleEmojiPicker: {
      type: Function,
      default: () => {},
    },
    toggleAudioRecorder: {
      type: Function,
      default: () => {},
    },
    toggleAudioRecorderPlayPause: {
      type: Function,
      default: () => {},
    },
    isRecordingAudio: {
      type: Boolean,
      default: false,
    },
    recordingAudioState: {
      type: String,
      default: '',
    },
    isSendDisabled: {
      type: Boolean,
      default: false,
    },
    showEditorToggle: {
      type: Boolean,
      default: false,
    },
    isOnPrivateNote: {
      type: Boolean,
      default: false,
    },
    enableMultipleFileUpload: {
      type: Boolean,
      default: true,
    },
    hasWhatsappTemplates: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date_time: '',
      show_schedule: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      signature: 'getMessageSignature',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      uiSettings: 'getUISettings',
      role: 'getEzytextRole',
      cannedMessages: 'getCannedResponses',
    }),
    signatureEnabled() {
      return this.uiSettings.send_with_signature;
    },
    isNote() {
      return this.mode === REPLY_EDITOR_MODES.NOTE;
    },
    wrapClass() {
      return {
        'is-note-mode': this.isNote,
      };
    },
    buttonClass() {
      return {
        warning: this.isNote,
      };
    },
    showAttachButton() {
      return this.showFileUpload || this.isNote;
    },
    showAudioRecorderButton() {
      return (
        this.isFeatureEnabledonAccount(
          this.accountId,
          FEATURE_FLAGS.VOICE_RECORDER
        ) && this.showAudioRecorder
      );
    },
    showAudioPlayStopButton() {
      return this.showAudioRecorder && this.isRecordingAudio;
    },
    allowedFileTypes() {
      if (this.isATwilioWhatsAppChannel) {
        return ALLOWED_FILE_TYPES_FOR_TWILIO_WHATSAPP;
      }
      return ALLOWED_FILE_TYPES;
    },
    audioRecorderPlayStopIcon() {
      switch (this.recordingAudioState) {
        // playing paused recording stopped inactive destroyed
        case 'playing':
          return 'microphone-pause';
        case 'paused':
          return 'microphone-play';
        case 'stopped':
          return 'microphone-play';
        default:
          return 'microphone-stop';
      }
    },
    showMessageSignatureButton() {
      return !this.isPrivate && this.isAnEmailChannel;
    },
    showSchedule() {
      return !this.$parent.isPrivate;
    },
    sendWithSignature() {
      const { send_with_signature: isEnabled } = this.uiSettings;
      return isEnabled;
    },
    signatureToggleTooltip() {
      return this.sendWithSignature
        ? this.$t('CONVERSATION.FOOTER.DISABLE_SIGN_TOOLTIP')
        : this.$t('CONVERSATION.FOOTER.ENABLE_SIGN_TOOLTIP');
    },
    reviewCannedMessage() {
      return this.cannedMessages.filter(cannedMessage => {
        return cannedMessage.short_code === 'google';
      })[0];
    },
  },
  watch: {
    date_time(value) {
      if (!value) {
        this.show_schedule = false;
      }
      this.$emit('setScheduleTime', value);
    },
  },
  created() {
    this.fetchCannedResponses();
  },
  mounted() {
    ActiveStorage.start();
    this.getProfileSetting();
  },
  methods: {
    fetchCannedResponses() {
      this.$store.dispatch('getCannedResponse', {});
    },
    SendMessage() {
      this.show_schedule = false;
      this.date_time = '';
      this.onSend();
    },
    addCannedMessage() {
      this.$emit('addGoogleReview', this.reviewCannedMessage.content);
    },
    enableSchedule() {
      this.show_schedule = !this.show_schedule;
      if (!this.show_schedule) {
        this.date_time = '';
      } else {
        this.$nextTick(() => {
          let datepicker = document.getElementById('date_picker');
          let today = new Date();
          today.setSeconds(0, 0);
          datepicker.min = today.toISOString().replace('Z', '');
        });
      }
    },
    handleKeyEvents(e) {
      if (hasPressedAltAndAKey(e)) {
        this.$refs.upload.$children[1].$el.click();
      }
    },
    toggleMessageSignature() {
      this.updateUISettings({
        send_with_signature: !this.sendWithSignature,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-box {
  display: flex;
  justify-content: space-between;
  padding: var(--space-slab) var(--space-normal);

  &.is-note-mode {
    background: var(--y-50);
  }
}
.signature-box {
  display: flex;
  padding: 0 var(--space-normal);
  color: var(--s-600);
  &.is-note-mode {
    background: var(--y-50);
  }
}

.left-wrap .button {
  margin-right: var(--space-small);
}

.left-wrap {
  align-items: center;
  display: flex;
}

.right-wrap {
  display: flex;
}

::v-deep .file-uploads {
  label {
    cursor: pointer;
  }
  &:hover .button {
    background: var(--s-100);
  }
}

.modal-mask {
  color: var(--s-600);
  background: var(--white-transparent);
  flex-direction: column;
}

.page-sub-title {
  color: var(--s-600);
}

.icon {
  font-size: 8rem;
}

.margin-r {
  margin-top: 3px !important;
  margin-right: 5px !important;
}
</style>
<style>
[type='datetime-local'] {
  border: none !important;
  background-color: var(--s-50);
  margin: 0;
  padding: 0 0.8rem;
  height: 3.18rem;
  width: auto;
  font-size: var(--font-size-mini);
}
</style>
