/* global axios */
import ApiClient from './ApiClient';

class KeywordApi extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  getKeywords() {
    return axios.get(`${this.url}/chat_keywords`);
  }

  create(data) {
    return axios.post(`${this.url}/chat_keywords`, data);
  }

  updateStatus(data) {
    return axios.post(`${this.url}/chat_keywords/status_update`, data);
  }

  update(data) {
    let payload = {
      key_word: data.key_word,
      response_text: data.response_text,
    };
    return axios.patch(`${this.url}chat_keywords/${data.id}`, payload);
  }

  delete(id) {
    return axios.delete(`${this.url}chat_keywords/${id}`);
  }
}

export default new KeywordApi();
