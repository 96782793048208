<template>
  <div class="">
    <div class="online">
      <div class="image">
        <img :src="image" />
      </div>
      <div class="ezybot-text">ezybot</div>
      <svg
        width="10"
        height="10"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        icon="Dot"
        class="dot"
      >
        <circle cx="4" cy="4" r="4" style="fill: #039855;" />
      </svg>
      <div class="online-text">Online</div>
    </div>
    <form class="row" @submit.prevent="getAiContent()">
      <div class="medium-12 columns mb">
        <AiParagraph paragraph="Welcome to Ezyagent’s AI Assistant 👋" />
        <AiParagraph
          :hide-avatar="true"
          paragraph="What kind of response are you looking for?"
        />
      </div>
      <div class="medium-12 columns">
        <label :class="{ error: $v.voice_tone.$error }">
          <single-select-dropdown
            class="medium-12 columns"
            :label="'Voice Tone'"
            :selected="voice_tone"
            :options="voiceToneList"
            :action="voiceToneHandler"
          />
        </label>
      </div>
      <div class="medium-12 columns">
        <label :class="{ error: $v.writing_style.$error }">
          <single-select-dropdown
            class="medium-12 columns"
            :label="'Writing style'"
            :selected="writing_style"
            :options="writingList"
            :action="writingHandler"
          />
        </label>
      </div>
      <div class="medium-12 columns">
        <label :class="{ error: $v.language.$error }">
          <single-select-dropdown
            class="medium-12 columns"
            :label="'Language'"
            :selected="language"
            :options="languageList"
            :action="languageHandler"
          />
        </label>
      </div>

      <div class="medium-12 columns">
        <label :class="{ error: $v.description.$error }">
          Details
          <textarea
            v-model.trim="description"
            rows="5"
            type="text"
            placeholder="Harness the efficiency of AI – ask for a tailored canned response"
            @input="$v.description.$touch"
          />
        </label>
      </div>
      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-submit-button
            :disabled="
              $v.description.$invalid ||
                $v.voice_tone.$invalid ||
                $v.writing_style.$invalid ||
                $v.language.$invalid ||
                addCanned.showLoading
            "
            :button-text="$t('CANNED_MGMT.ADD.FORM.SUBMIT')"
            :loading="addCanned.showLoading"
          />
          <button class="button clear" @click.prevent="$emit('cancel')">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required /* , minLength */ } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import alertMixin from 'shared/mixins/alertMixin';
import SingleSelectDropdown from '../inbox/components/SingleSelectDropdown';
import AiParagraph from './AiParagraph.vue';
import image from 'shared/assets/images/fav-icon.png';

export default {
  components: {
    WootSubmitButton,
    SingleSelectDropdown,
    AiParagraph,
  },
  mixins: [alertMixin],
  props: {},
  data() {
    return {
      image,
      shortCode: '',
      description: '',
      addCanned: {
        showLoading: false,
      },
      voice_tone: '',
      writing_style: '',
      language: '',
      shorten: false,
      voiceToneList: [
        {
          key: 0,
          value: 'Authoritative',
        },
        {
          key: 1,
          value: 'Caring',
        },
        {
          key: 2,
          value: 'Casual',
        },
        {
          key: 3,
          value: 'Cheerful',
        },
        {
          key: 4,
          value: 'Coarse',
        },
        {
          key: 5,
          value: 'Conservative',
        },
        {
          key: 6,
          value: 'Conversational',
        },
        {
          key: 7,
          value: 'Creative',
        },
        {
          key: 8,
          value: 'Dry',
        },
        {
          key: 9,
          value: 'Edgy',
        },
        {
          key: 10,
          value: 'Enthusiastic',
        },
        {
          key: 11,
          value: 'Expository',
        },
        {
          key: 12,
          value: 'Formal',
        },
        {
          key: 13,
          value: 'Frank',
        },
        {
          key: 14,
          value: 'Friendly',
        },
        {
          key: 15,
          value: 'Fun',
        },
        {
          key: 16,
          value: 'Funny',
        },
        {
          key: 17,
          value: 'Humorous',
        },
        {
          key: 18,
          value: 'Informative',
        },
        {
          key: 19,
          value: 'Irreverent',
        },
        {
          key: 20,
          value: 'Journalistic',
        },
        {
          key: 21,
          value: 'Matter',
        },
        {
          key: 22,
          value: 'Nostalgic',
        },
        {
          key: 23,
          value: 'Objective',
        },
        {
          key: 24,
          value: 'Passionate',
        },
        {
          key: 25,
          value: 'Poetic',
        },
        {
          key: 26,
          value: 'Playful',
        },
        {
          key: 27,
          value: 'Professional',
        },
        {
          key: 28,
          value: 'Provocative',
        },
        {
          key: 29,
          value: 'Quirky',
        },
        {
          key: 30,
          value: 'Respectful',
        },
        {
          key: 31,
          value: 'Romantic',
        },
        {
          key: 32,
          value: 'Sarcastic',
        },
        {
          key: 33,
          value: 'Serious',
        },
        {
          key: 34,
          value: 'Smart',
        },
        {
          key: 35,
          value: 'Snarky',
        },
        {
          key: 36,
          value: 'Subjective',
        },
        {
          key: 37,
          value: 'Sympathetic',
        },
        {
          key: 38,
          value: 'Trendy',
        },
        {
          key: 39,
          value: 'Trustworthy',
        },
        {
          key: 40,
          value: 'Unapologetic',
        },
        {
          key: 41,
          value: 'Upbeat',
        },
        {
          key: 42,
          value: 'Witty',
        },
      ],
      writingList: [
        {
          key: 0,
          value: 'Academic',
        },
        {
          key: 1,
          value: 'Analytical',
        },
        {
          key: 2,
          value: 'Argumentative',
        },
        {
          key: 3,
          value: 'Conversational',
        },
        {
          key: 4,
          value: 'Creative',
        },
        {
          key: 5,
          value: 'Critical',
        },
        {
          key: 6,
          value: 'Descriptive',
        },
        {
          key: 7,
          value: 'Epigrammatic',
        },
        {
          key: 8,
          value: 'Epistolary',
        },
        {
          key: 9,
          value: 'Expository',
        },
        {
          key: 10,
          value: 'Informative',
        },
        {
          key: 11,
          value: 'Instructive',
        },
        {
          key: 12,
          value: 'Journalistic',
        },
        {
          key: 13,
          value: 'Metaphorical',
        },
        {
          key: 14,
          value: 'Narrative',
        },
        {
          key: 15,
          value: 'Persuasive',
        },
        {
          key: 16,
          value: 'Poetic',
        },
        {
          key: 17,
          value: 'Satirical',
        },
        {
          key: 18,
          value: 'Technical',
        },
      ],
      languageList: [
        { key: 1, value: 'English' },
        { key: 2, value: 'Spanish' },
      ],
      show: true,
    };
  },
  validations: {
    voice_tone: {
      required,
    },
    language: {
      required,
    },
    writing_style: {
      required,
    },
    description: {
      required,
    },
  },
  methods: {
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    voiceToneHandler(mode) {
      this.voice_tone = mode;
    },
    writingHandler(mode) {
      this.writing_style = mode;
    },
    languageHandler(mode) {
      this.language = mode;
    },
    getAiContent() {
      // Show loading on button
      this.addCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('getAiContent', {
          voice_tone: this.voice_tone,
          writing_style: this.writing_style,
          language: this.language,
          description: this.description,
        })
        .then(res => {
          // Reset Form, Show success message
          this.addCanned.showLoading = false;
          // this.showAlert(this.$t('CANNED_MGMT.ADD.API.SUCCESS_MESSAGE'));
          // this.resetForm();
          this.$emit('updateBody', res.response);
          this.$emit('cancel');
          // this.onClose();
        })
        .catch(() => {
          this.addCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.ADD.API.ERROR_MESSAGE'));
        });
    },
  },
};
</script>

<style scoped>
.mb {
  margin-bottom: 10px;
}
.image {
  padding: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.online {
  margin-left: 25px;
  display: flex;
  gap: 4px;
  margin-bottom: -10px;
}
.ezybot-text {
  font-size: 14px;
  margin-top: auto;
  font-weight: 600;
}
.dot {
  font-size: 14px;
  margin-top: auto;
  margin-bottom: 6px;
  margin-left: 3px;
  margin-right: 3px;
}
.online-text {
  font-size: 12px;
  margin-top: auto;
  font-weight: 600;
  margin-bottom: 1px;
}
</style>
