import Vue from 'vue';
import types from '../mutation-types';
import authAPI from '../../api/auth';
import BillingApi from '../../api/billing';

import {
  setUser,
  clearCookiesOnLogout,
  clearLocalStorageOnLogout,
} from '../utils/api';
import { getLoginRedirectURL } from '../../helper/URLHelper';
import ezyagent from '../../api/ezyagent';

const initialState = {
  currentUser: {
    id: null,
    account_id: null,
    accounts: [],
    email: null,
    name: null,
  },
  planSubscribed: 'true',
  paidEzyAgent: true,
  uiFlags: {
    isFetching: true,
  },
};

// getters
export const getters = {
  isLoggedIn($state) {
    return !!$state.currentUser.id;
  },

  getCurrentUserID($state) {
    return $state.currentUser.id;
  },

  getEzyagentSubscription($state) {
    return $state.paidEzyAgent;
  },

  getUISettings($state) {
    return $state.currentUser.ui_settings || {};
  },

  getAuthUIFlags($state) {
    return $state.uiFlags;
  },

  getCurrentUserAvailability($state, $getters) {
    const { accounts = [] } = $state.currentUser;
    const [currentAccount = {}] = accounts.filter(
      account => account.id === $getters.getCurrentAccountId
    );
    return currentAccount.availability;
  },

  getCurrentAccountId(_, __, rootState) {
    if (rootState.route.params && rootState.route.params.accountId) {
      return Number(rootState.route.params.accountId);
    }
    return null;
  },

  getCurrentRole($state, $getters) {
    const { accounts = [] } = $state.currentUser;
    const [currentAccount = {}] = accounts.filter(
      account => account.id === $getters.getCurrentAccountId
    );
    return currentAccount.role;
  },

  getEzytextRole($state, $getters) {
    const { accounts = [] } = $state.currentUser;
    const [currentAccount = {}] = accounts.filter(
      account => account.id === $getters.getCurrentAccountId
    );
    return currentAccount.user_type === 'ezy_agent';
  },

  isUserEZYAgentOrEZYText($state, $getters) {
    const { accounts = [] } = $state.currentUser;
    const [currentAccount = {}] = accounts.filter(
      account => account.id === $getters.getCurrentAccountId
    );
    return ['ezy_agent', 'ezy_text'].includes(currentAccount.user_type);
  },

  getCurrentUser($state) {
    return $state.currentUser;
  },

  getMessageSignature($state) {
    const { message_signature: messageSignature } = $state.currentUser;

    return messageSignature || '';
  },

  getCurrentAccount($state, $getters) {
    const { accounts = [] } = $state.currentUser;
    const [currentAccount = {}] = accounts.filter(
      account => account.id === $getters.getCurrentAccountId
    );
    return currentAccount || {};
  },

  getUserAccounts($state) {
    const { accounts = [] } = $state.currentUser;
    return accounts;
  },

  getPlanSubscription($state) {
    return $state.planSubscribed;
  },
};

// actions
export const actions = {
  login(
    _,
    {
      ssoAccountId,
      ssoConversationId,
      userAccountId,
      userConversationId,
      newConversationText,
      conversationNumber,
      ...credentials
    }
  ) {
    return new Promise((resolve, reject) => {
      authAPI
        .login(credentials)
        .then(response => {
          clearLocalStorageOnLogout();
          let redirectUrl = getLoginRedirectURL({
            ssoAccountId,
            ssoConversationId,
            user: response.data,
            userAccountId,
            userConversationId,
          });
          if (newConversationText) {
            redirectUrl += `?new_conversation_text=${newConversationText}`;
          }
          if (conversationNumber) {
            if (!newConversationText) {
              redirectUrl += `?`;
            } else {
              redirectUrl += `&`;
            }
            redirectUrl += `conversation_number=${conversationNumber}`;
          }
          window.location = redirectUrl;
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async validityCheck(context) {
    try {
      const response = await authAPI.validityCheck();
      const currentUser = response.data.payload.data;
      setUser(currentUser);
      context.commit(types.SET_CURRENT_USER, currentUser);
    } catch (error) {
      if (error?.response?.status === 401) {
        clearCookiesOnLogout();
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  isSubscribed: async ({ commit, state }) => {
    try {
      const response = await BillingApi.isSubscribed(
        state.currentUser.account_id
      );
      commit(types.SET_PLAN_SUBSCRIBED, response.data.message);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
  subscribed: async ({ commit, state }) => {
    try {
      const response = await ezyagent.subscribed(state.currentUser.email);
      commit(types.SET_EZYAGENT_SUBSCRIBED, response.data.paid);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
  async setUser({ commit, dispatch }) {
    if (authAPI.hasAuthCookie()) {
      await dispatch('validityCheck');
      await dispatch('isSubscribed');
      // await dispatch('subscribed');
    } else {
      commit(types.CLEAR_USER);
    }
    commit(types.SET_CURRENT_USER_UI_FLAGS, { isFetching: false });
  },
  logout({ commit }) {
    commit(types.CLEAR_USER);
  },

  updateProfile: async ({ commit }, params) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await authAPI.profileUpdate(params);
      commit(types.SET_CURRENT_USER, response.data);
    } catch (error) {
      throw error;
    }
  },

  deleteAvatar: async () => {
    try {
      await authAPI.deleteAvatar();
    } catch (error) {
      // Ignore error
    }
  },

  updateUISettings: async ({ commit }, params) => {
    try {
      commit(types.SET_CURRENT_USER_UI_SETTINGS, params);
      const response = await authAPI.updateUISettings(params);
      commit(types.SET_CURRENT_USER, response.data);
    } catch (error) {
      // Ignore error
    }
  },

  getProfileSetting: async ({ commit }) => {
    try {
      const response = await authAPI.getProfileSetting();
      commit(types.SET_CURRENT_USER, response.data);
    } catch (error) {
      // Ignore error
    }
  },

  updateAvailability: async ({ commit, dispatch }, params) => {
    try {
      const response = await authAPI.updateAvailability(params);
      const userData = response.data;
      const { id } = userData;
      commit(types.SET_CURRENT_USER, response.data);
      dispatch('agents/updateSingleAgentPresence', {
        id,
        availabilityStatus: params.availability,
      });
    } catch (error) {
      // Ignore error
    }
  },

  setCurrentUserAvailability({ commit, state: $state }, data) {
    if (data[$state.currentUser.id]) {
      commit(types.SET_CURRENT_USER_AVAILABILITY, data[$state.currentUser.id]);
    }
  },

  setActiveAccount: async (_, { accountId }) => {
    try {
      await authAPI.setActiveAccount({ accountId });
    } catch (error) {
      // Ignore error
    }
  },
};

// mutations
export const mutations = {
  [types.SET_CURRENT_USER_AVAILABILITY](_state, availability) {
    Vue.set(_state.currentUser, 'availability', availability);
  },
  [types.CLEAR_USER](_state) {
    _state.currentUser = initialState.currentUser;
  },
  [types.SET_CURRENT_USER](_state, currentUser) {
    Vue.set(_state, 'currentUser', currentUser);
  },
  [types.SET_PLAN_SUBSCRIBED](state, planSubscribed) {
    state.planSubscribed = planSubscribed;
  },
  [types.SET_CURRENT_USER_UI_SETTINGS](_state, { uiSettings }) {
    Vue.set(_state, 'currentUser', {
      ..._state.currentUser,
      ui_settings: {
        ..._state.currentUser.ui_settings,
        ...uiSettings,
      },
    });
  },
  [types.SET_EZYAGENT_SUBSCRIBED](state, data) {
    state.paidEzyAgent = data;
  },
  [types.SET_CURRENT_USER_UI_FLAGS](_state, { isFetching }) {
    Vue.set(_state, 'uiFlags', { isFetching });
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
