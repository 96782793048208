import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';
import TwilioChannelApi from '../../api/channel/twilioChannel';

const state = {
  hosted_number: '',
};

export const getters = {
  getHostedNumber($state) {
    return $state.hosted_number;
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  saveHostedNumber: async ({ commit }, data) => {
    try {
      const response = await TwilioChannelApi.saveHostedNumber(data);
      return response.data;
    } catch (error) {
      // ignore error
      return throwErrorMessage(error);
    }
  },
  // eslint-disable-next-line no-unused-vars
  removeHostedNumber: async ({ commit }) => {
    try {
      const response = await TwilioChannelApi.removeHostedNumber();
      return response.data;
    } catch (error) {
      // ignore error
      return throwErrorMessage(error);
    }
  },
  get: async ({ commit }) => {
    try {
      const response = await TwilioChannelApi.fetchHostedNumber();
      let { hosted_number } = response.data[0];
      if (hosted_number) {
        commit(types.SET_HOSTED_NUMBER, hosted_number);
      } else {
        commit(types.SET_HOSTED_NUMBER, '');
      }
    } catch (error) {
      // ignore error
    }
  },
  setHostedNumber: async ({ commit }, data) => {
    commit(types.SET_HOSTED_NUMBER, data);
  },
};

export const mutations = {
  [types.SET_HOSTED_NUMBER]($state, data) {
    $state.hosted_number = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
