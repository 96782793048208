<template>
  <woot-button
    class-names="notifications-link--button"
    variant="clear"
    color-scheme="secondary"
    :class="{ 'is-active': isContactPanelActive }"
    @click="openContactPanel"
  >
    <!-- <fluent-icon icon="home" /> -->
    <img
      class="ezyagent-img"
      src="~dashboard/assets/images/fav.png"
      alt="Home"
    />
  </woot-button>
</template>
<script>
import { mapGetters } from 'vuex';
import Auth from '../../../api/auth';

export default {
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    isContactPanelActive() {
      return this.$route.name === 'contact_us';
    },
  },
  methods: {
    openContactPanel() {
      Auth.Home();
      // this.$emit('open-home');
    },
  },
};
</script>

<style scoped lang="scss">
.notifications-link {
  margin-bottom: var(--space-small);
}

.badge {
  position: absolute;
  right: var(--space-minus-smaller);
  top: var(--space-minus-smaller);
}
.notifications-link--button {
  display: flex;
  position: relative;
  border-radius: var(--border-radius-large);
  border: 1px solid transparent;
  color: var(--s-600);
  margin: var(--space-small) 0;

  &:hover {
    background: var(--w-50);
    color: var(--s-600);
  }

  &:focus {
    border-color: var(--w-500);
  }

  &.is-active {
    background: var(--w-50);
    color: var(--w-500);
  }
}
.ezyagent-img {
  width: 22px;
}
</style>
