<template>
  <div class="agent-table-container">
    <ve-table
      :fixed-header="true"
      :columns="columns"
      :table-data="newTableData"
    />
    <div v-if="isLoading" class="agents-loader">
      <spinner />
      <span>{{
        $t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.LOADING_MESSAGE')
      }}</span>
    </div>
    <empty-state
      v-else-if="!isLoading && !schedules.length"
      title="There are no Scheduled messages by agents."
    />
    <!-- <div v-if="keywords.length > 0" class="table-pagination">
      <ve-pagination
        :total="keywords.length"
        :page-index="pageIndex"
        :page-size="25"
        :page-size-option="[25]"
        @on-page-number-change="onPageNumberChange"
      />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';

export default {
  name: 'ScheduleMessageTable',
  components: {
    EmptyState,
    Spinner,
    VeTable,
    // VePagination,
  },
  props: {
    agentMetrics: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      keywords: 'chat_keywords/getAllKeyword',
      schedules: 'scheduleMessages/getAllSchedules',
    }),
    newTableData() {
      return this.schedules.map((message, index) => {
        return {
          rowKey: index,
          id: message.id,
          content: message.content,
          schedule_date: message.schedule_date,
          sender: message.sender.available_name,
        };
      });
    },
    columns() {
      return [
        {
          field: 'content',
          key: 'content',
          title: 'Message Content',
          align: 'left',
          width: 10,
        },
        {
          field: 'schedule_date',
          key: 'schedule_date',
          title: 'Schedule Date/time',
          align: 'left',
          width: 10,
        },
        {
          field: 'sender',
          key: 'sender',
          title: 'Sender',
          align: 'left',
          width: 10,
        },
        {
          field: 'id',
          key: 'id',
          title: 'Actions',
          align: 'left',
          width: 4,
          renderBodyCell: ({ row }) => {
            return (
              <span>
                <button
                  class="button-demo download-btn"
                  on-click={() => this.editKeyword(row)}
                >
                  <fluent-icon icon="edit" />
                </button>
              </span>
            );
          },
        },
      ];
    },
  },
  methods: {
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
    getAgentInformation(id) {
      return this.agents.find(agent => agent.id === Number(id));
    },
    editKeyword(item) {
      this.$emit('edit-schedule', item);
    },
    deleteKeyword(item) {
      this.$emit('delete-schedule', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    &::v-deep {
      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-small) var(--space-two) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-one) var(--space-two) !important;
        min-height: calc(100vh - 35px) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .row-user-block {
    align-items: center;
    display: flex;
    text-align: left;

    .user-block {
      display: flex;
      flex-direction: column;
      min-width: 0;
      .title {
        font-size: var(--font-size-small);
        margin: var(--zero);
        line-height: 1;
      }
      .sub-title {
        font-size: var(--font-size-mini);
      }
    }

    .user-thumbnail-box {
      margin-right: var(--space-small);
    }
  }

  .table-pagination {
    margin-top: var(--space-normal);
    text-align: right;
  }
}

.agents-loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-large);
}
.live {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: var(--space-small);
  padding-left: var(--space-small);
  margin: var(--space-smaller);
  background: rgba(37, 211, 102, 0.1);
  color: var(--g-400);
  font-size: var(--font-size-mini);
  width: 74px;

  .ellipse {
    background-color: var(--g-400);
    height: var(--space-smaller);
    width: var(--space-smaller);
    border-radius: var(--border-radius-rounded);
    margin-right: var(--space-smaller);
  }
}
.download-btn {
  cursor: pointer !important;
}
</style>
<style>
.ve-table
  .ve-table-container
  .ve-table-content
  tbody.ve-table-body
  .ve-table-body-tr
  .ve-table-body-td.ve-table-cell-selection {
  border: none !important;
}
</style>
