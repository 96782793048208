// import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';
import KeywordsAPI from '../../api/keywords';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getAllKeyword($state) {
    return $state.records;
  },
};

export const actions = {
  get: async ({ commit }) => {
    try {
      const response = await KeywordsAPI.getKeywords();
      commit(types.SET_KEYWORDS, response.data);
    } catch (error) {
      // Ignore error
    }
  },
  // eslint-disable-next-line no-unused-vars
  create: async ({ commit }, data) => {
    try {
      const response = await KeywordsAPI.create(data);
      return response.data;
    } catch (error) {
      // Ignore error
      return throwErrorMessage(error);
    }
  },
  // eslint-disable-next-line no-unused-vars
  update_status: async ({ commit }, data) => {
    try {
      const response = await KeywordsAPI.updateStatus(data);
      return response.data;
    } catch (error) {
      // Ignore error
      return throwErrorMessage(error);
    }
  },
  // eslint-disable-next-line no-unused-vars
  update: async ({ commit }, data) => {
    try {
      const response = await KeywordsAPI.update(data);
      return response.data;
    } catch (error) {
      // Ignore error
      return throwErrorMessage(error);
    }
  },
  // eslint-disable-next-line no-unused-vars
  delete: async ({ commit }, data) => {
    try {
      const response = await KeywordsAPI.delete(data);
      return response.data;
    } catch (error) {
      // Ignore error
      return throwErrorMessage(error);
    }
  },
};

export const mutations = {
  [types.SET_KEYWORDS]($state, data) {
    $state.records = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
