/* global axios */
import ApiClient from './ApiClient';

class ScheduleApi extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  getschedules() {
    return axios.get(`${this.url}scheduled_messages`);
  }

  //   create(data) {
  //     return axios.post(`${this.url}/chat_keywords`, data);
  //   }

  //   updateStatus(data) {
  //     return axios.post(`${this.url}/chat_keywords/status_update`, data);
  //   }

  update(data) {
    let payload = {
      id: data.id,
      schedule_date: data.schedule_date,
      content: data.content,
    };
    return axios.put(`${this.url}scheduled_messages/${data.id}`, payload);
  }

  delete(id) {
    return axios.delete(`${this.url}scheduled_messages/${id}`);
  }
}

export default new ScheduleApi();
