<template>
  <woot-modal
    :show="show"
    :on-close="() => $emit('close-account-create-modal')"
    class="account-selector--modal"
  >
    <div class="column content-box">
      <woot-modal-header header-title="Renew Your Account" />
      <div v-if="true" class="alert-wrap">
        <div class="callout alert">
          <div class="icon-wrap">
            <fluent-icon icon="warning" />
          </div>
          Uh oh! Your account subscription is expired. Please renew your
          subscription to continue.
        </div>
      </div>
      <form class="row" @submit.prevent="renewAccount">
        <div v-if="userRole !== 'agent'" class="modal-footer medium-12 columns">
          <woot-submit-button
            button-text="RENEW"
            :loading="loading"
            button-class="large expanded"
          />
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import Auth from '../../../api/auth';
import { mapGetters } from 'vuex';
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hasAccounts: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      accountName: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'getCurrentRole',
    }),
  },
  methods: {
    renewAccount() {
      this.loading = true;
      Auth.Renew();
    },
  },
};
</script>
<style lang="scss" scoped>
.alert-wrap {
  font-size: var(--font-size-small);
  margin: var(--space-medium) var(--space-large) var(--space-zero);

  .callout {
    align-items: center;
    border-radius: var(--border-radius-normal);
    display: flex;
  }
}

.icon-wrap {
  margin-left: var(--space-smaller);
  margin-right: var(--space-slab);
}

.modal-header .close {
  display: none;
}
</style>
