<template>
  <div class="flex-container actions--container">
    <woot-button
      v-if="!currentChat.muted && !role"
      v-tooltip="$t('CONTACT_PANEL.MUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-mute"
      @click="mute"
    />
    <woot-button
      v-else-if="!role"
      v-tooltip.left="$t('CONTACT_PANEL.UNMUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-1"
      @click="unmute"
    />
    <woot-button
      v-tooltip="'Print Transcript'"
      variant="clear"
      :class="{ 'align-buttons-bottom': role }"
      color-scheme="secondary"
      icon="print"
      @click="printChat"
    />
    <woot-button
      v-if="role"
      v-tooltip="
        currentChat.meta.sender.blocked ? 'UnBlock Contact' : 'Block Contact'
      "
      variant="clear"
      :class="{ 'align-buttons-bottom': role }"
      color-scheme="secondary"
      icon="block"
      @click="blockContact"
    />
    <woot-button
      v-tooltip="$t('CONTACT_PANEL.SEND_TRANSCRIPT')"
      class="right-margin"
      variant="clear"
      :class="{ 'align-buttons-bottom': role }"
      color-scheme="secondary"
      icon="share"
      @click="toggleEmailActionsModal"
    />
    <!-- <resolve-action
      :conversation-id="currentChat.id"
      :status="currentChat.status"
    /> -->
    <email-transcript-modal
      v-if="showEmailActionsModal"
      :show="showEmailActionsModal"
      :current-chat="currentChat"
      @cancel="toggleEmailActionsModal"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import EmailTranscriptModal from './EmailTranscriptModal';
// import ResolveAction from '../../buttons/ResolveAction';
import {
  CMD_MUTE_CONVERSATION,
  CMD_SEND_TRANSCRIPT,
  CMD_UNMUTE_CONVERSATION,
} from '../../../routes/dashboard/commands/commandBarBusEvents';

export default {
  components: {
    EmailTranscriptModal,
    // ResolveAction,
  },
  mixins: [alertMixin, clickaway],
  data() {
    return {
      showEmailActionsModal: false,
    };
  },
  computed: {
    ...mapGetters({ currentChat: 'getSelectedChat', role: 'getEzytextRole' }),
  },
  mounted() {
    bus.$on(CMD_MUTE_CONVERSATION, this.mute);
    bus.$on(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$on(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  destroyed() {
    bus.$off(CMD_MUTE_CONVERSATION, this.mute);
    bus.$off(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$off(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  methods: {
    blockContact() {
      if (this.currentChat.meta.sender.blocked) {
        this.$store.dispatch(
          'unblockConversation',
          this.currentChat.meta.sender.id
        );
        this.showAlert('Contact UnBlocked');
      } else {
        this.$store.dispatch(
          'blockConversation',
          this.currentChat.meta.sender.id
        );
        this.showAlert('Contact Blocked');
      }
    },
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'));
    },
    printChat() {
      var mywindow = window.open('', 'PRINT', 'height=400');
      var elem = 'chats-container';

      mywindow.document.write(
        '<html><head><title>' + document.title + '</title>'
      );
      mywindow.document.write('</head><body >');
      mywindow.document.write('<h1>' + document.title + '</h1>');
      mywindow.document.write(document.getElementById(elem).innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      return true;
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'));
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
    },
  },
};
</script>
<style scoped lang="scss">
.actions--container {
  align-items: center;

  .resolve-actions {
    margin-left: var(--space-small);
  }
}

.more--button {
  align-items: center;
  display: flex;
  margin-left: var(--space-small);
}

.actions--container {
  position: relative;
}

.dropdown-pane {
  right: var(--space-minus-small);
  top: 48px;
}

.icon {
  margin-right: var(--space-smaller);
  min-width: var(--space-normal);
}
.right-margin {
  margin-right: var(--space-two);
}
.align-buttons-bottom {
  align-items: end;
  padding: 0;
}
</style>
