<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('KEYWORDS_SETTINGS.ADD.TITLE')"
        :header-content="$t('KEYWORDS_SETTINGS.ADD.DESC')"
      />
      <form class="row" @submit.prevent="addAgent()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.keyword.$error }">
            {{ $t('KEYWORDS_SETTINGS.ADD.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="keyword"
              type="text"
              :placeholder="
                $t('KEYWORDS_SETTINGS.ADD.FORM.SHORT_CODE.PLACEHOLDER')
              "
              @input="$v.keyword.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.description.$error }">
            {{ $t('KEYWORDS_SETTINGS.ADD.FORM.CONTENT.LABEL') }}
            <textarea
              v-model.trim="description"
              rows="5"
              type="text"
              :placeholder="
                $t('KEYWORDS_SETTINGS.ADD.FORM.CONTENT.PLACEHOLDER')
              "
              @input="$v.description.$touch"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.description.$invalid ||
                  $v.keyword.$invalid ||
                  addCanned.showLoading
              "
              :button-text="$t('KEYWORDS_SETTINGS.ADD.FORM.SUBMIT')"
              :loading="addCanned.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('KEYWORDS_SETTINGS.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  mixins: [alertMixin],
  props: {
    responseContent: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      keyword: '',
      description: '',
      addCanned: {
        showLoading: false,
        message: '',
      },
      show: true,
    };
  },
  validations: {
    keyword: {
      required,
      minLength: minLength(2),
    },
    description: {
      required,
    },
  },
  methods: {
    resetForm() {
      this.keyword = '';
      this.description = '';
      this.$v.keyword.$reset();
      this.$v.description.$reset();
    },
    addAgent() {
      // Show loading on button
      this.addCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('chat_keywords/create', {
          key_word: this.keyword,
          response_text: this.description,
          status: true,
        })
        .then(() => {
          // Reset Form, Show success message
          this.addCanned.showLoading = false;
          this.$store.dispatch('chat_keywords/get');
          this.showAlert('Keyword Saved Successfully');
          this.resetForm();
          this.onClose();
        })
        .catch(() => {
          this.addCanned.showLoading = false;
          this.showAlert('Error while creating keyword');
        });
    },
  },
};
</script>
