<template>
  <div class="medium-12 column login">
    <div class="text-center medium-12 login__hero align-self-top">
      <h2 class="hero__title">
        {{
          useInstallationName($t('LOGIN.TITLE'), globalConfig.installationName)
        }}
      </h2>
    </div>
    <div class="row align-center">
      <div v-if="!email" class="small-12 medium-4 column">
        <form class="login-box column align-self-top" @submit.prevent="login()">
          <div class="column log-in-form">
            <label :class="{ error: $v.credentials.email.$error }">
              {{ $t('LOGIN.EMAIL.LABEL') }}
              <input
                v-model.trim="credentials.email"
                type="text"
                data-testid="email_input"
                :placeholder="$t('LOGIN.EMAIL.PLACEHOLDER')"
                @input="$v.credentials.email.$touch"
              />
            </label>
            <label :class="{ error: $v.credentials.password.$error }">
              {{ $t('LOGIN.PASSWORD.LABEL') }}
              <input
                v-model.trim="credentials.password"
                type="password"
                data-testid="password_input"
                :placeholder="$t('LOGIN.PASSWORD.PLACEHOLDER')"
                @input="$v.credentials.password.$touch"
              />
            </label>
            <woot-submit-button
              :disabled="
                $v.credentials.email.$invalid ||
                  $v.credentials.password.$invalid ||
                  loginApi.showLoading
              "
              :button-text="$t('LOGIN.SUBMIT')"
              :loading="loginApi.showLoading"
              button-class="large expanded"
            />
          </div>
          <div class="ezyagent-login">
            <hr class="hr-line-left" />
            <span class="or-text">OR</span>
            <hr class="hr-line-right" />
          </div>
          <div class="column text-center login-ezyagent">
            <p>
              <span @click="loginEzyAgent">
                <b>Login with </b>
                <img src="../../assets/images/ezyagentlogo.svg" />
              </span>
            </p>
          </div>
        </form>
        <div class="column text-center sigin__footer">
          <p v-if="!globalConfig.disableUserProfileUpdate">
            <router-link to="auth/reset/password">
              {{ $t('LOGIN.FORGOT_PASSWORD') }}
            </router-link>
          </p>
          <p>
            <router-link to="auth/signup">
              {{ $t('LOGIN.CREATE_NEW_ACCOUNT') }}
            </router-link>
          </p>
        </div>
      </div>
      <woot-spinner v-else size="" />
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import WootSubmitButton from '../../components/buttons/FormSubmitButton';
import { mapGetters } from 'vuex';
import Auth from '../../api/auth';

export default {
  components: {
    WootSubmitButton,
  },
  mixins: [globalConfigMixin],
  props: {
    ssoAuthToken: { type: String, default: '' },
    ssoAccountId: { type: String, default: '' },
    ssoConversationId: { type: String, default: '' },
    config: { type: String, default: '' },
    email: { type: String, default: '' },
    ezyAgentToken: { type: String, default: '' },
    pubsubToken: { type: String, default: '' },
    userAccountId: { type: String, default: '' },
    userConversationId: { type: String, default: '' },
    newConversationText: { type: String, default: '' },
    conversationNumber: { type: String, default: '' },
  },
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        email: '',
        password: '',
      },
      loginApi: {
        message: '',
        showLoading: false,
      },
      error: '',
    };
  },
  validations: {
    credentials: {
      password: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  created() {
    if (this.ezyAgentToken) {
      this.newLogin();
    } else if (this.ssoAuthToken) {
      this.login();
    }
    // console.log(window.chatwootConfig);
  },
  methods: {
    loginEzyAgent() {
      window.location = 'https://api.ezyagent.com/home/index';
    },
    clearAuthCookiesAtLogin() {
      Auth.clearAuthCookies();
    },
    showAlert(message) {
      // Reset loading, current selected agent
      this.loginApi.showLoading = false;
      this.loginApi.message = message;
      bus.$emit('newToastMessage', this.loginApi.message);
    },
    showSignupLink() {
      return window.chatwootConfig.signupEnabled === 'true';
    },
    newLogin() {
      this.clearAuthCookiesAtLogin();
      const token = {
        token: this.ezyAgentToken,
        pubsub_token: this.pubsubToken,
        email: this.email,
        userAccountId: this.userAccountId,
        userConversationId: this.userConversationId,
        newConversationText: this.newConversationText,
        conversationNumber: this.conversationNumber,
      };
      this.$store.dispatch('login', token).then(() => {
        this.showAlert(this.$t('LOGIN.API.SUCCESS_MESSAGE'));
      });
    },
    login() {
      this.loginApi.showLoading = true;
      const credentials = {
        email: this.email
          ? decodeURIComponent(this.email)
          : this.credentials.email,
        password: this.credentials.password,
        sso_auth_token: this.ssoAuthToken,
        ssoAccountId: this.ssoAccountId,
        ssoConversationId: this.ssoConversationId,
      };
      this.$store
        .dispatch('login', credentials)
        .then(() => {
          this.showAlert(this.$t('LOGIN.API.SUCCESS_MESSAGE'));
        })
        .catch(response => {
          // Reset URL Params if the authentication is invalid
          if (this.email) {
            window.location = '/app/login';
          }

          if (response && response.status === 401) {
            const { errors } = response.data;
            const hasAuthErrorMsg =
              errors &&
              errors.length &&
              errors[0] &&
              typeof errors[0] === 'string';
            if (hasAuthErrorMsg) {
              this.showAlert(errors[0]);
            } else {
              this.showAlert(this.$t('LOGIN.API.UNAUTH'));
            }
            return;
          }
          this.showAlert(this.$t('LOGIN.API.ERROR_MESSAGE'));
        });
    },
  },
};
</script>
<style scoped>
.hr-line-left {
  width: 45%;
  margin-left: 0;
  margin-right: 10px;
  color: #8e8e8e;
}
.hr-line-right {
  width: 45%;
  margin-right: 0;
  margin-left: 10px;
  color: #8e8e8e;
}
.ezyagent-login {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}
.or-text {
  font-size: 14px;
  color: #8e8e8e;
  font-weight: 700;
}
.login-ezyagent {
  cursor: pointer;
}
</style>
