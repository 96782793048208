<template>
  <div>
    <h1 class="heading-jumbo">Add payment method</h1>
    <div class="medium-6 small-12 column">
      <div class="card">
        <fluent-icon icon="credit-card-person" />
        <p>Card</p>
      </div>
      <p>Card details</p>
      <stripe-element-card
        ref="elementRef"
        class="stripeCard"
        :pk="publishableKey"
        @token="tokenCreated"
      />
      <label :class="{ error: $v.email.$error }">
        <p class="label">Email (optional)</p>
        <input
          v-model.trim="email"
          type="email"
          class="email-input"
          placeholder="Please enter your email address"
          @input="$v.email.$touch"
        />
        <span v-if="$v.email.$error" class="message">
          {{ $t('PROFILE_SETTINGS.FORM.EMAIL.ERROR') }}
        </span>
      </label>
      <p class="description-text">
        By providing your card information, you allow EzyText Inc to charge your
        card for future payments in accordance with their terms.
      </p>
      <div class="column">
        <woot-button
          :is-loading="loading"
          :is-disabled="disable"
          :is-expanded="true"
          @click="submit"
        >
          Add
        </woot-button>
      </div>
      <p class="description-second">
        You can review important information from EzyText Inc on their
        <strong>Terms of Service</strong> and
        <strong>Privacy Policy</strong> pages.
      </p>
    </div>
  </div>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { frontendURL } from '../../../../helper/URLHelper';
export default {
  components: {
    StripeElementCard,
  },
  data() {
    return {
      token: null,
      email: '',
      loading: false,
      disable: false,
      publishableKey:
        'pk_test_51N5UJUD4D0h3IFqX9OO6czBYmeY7qIeX8kcoM9HenAMONbW2AgPv28A9pPWOBN4aNOUnANT2t7sYjPz2oKUQ50vO00W2fW5lX4',
    };
  },
  validations: {
    email: {
      email,
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isSubscribed: 'getPlanSubscription',
      card: 'billing/getCards',
    }),
  },
  mounted() {
    this.getCards();
  },
  methods: {
    frontendURL,
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      // this will trigger the process
      this.$refs.elementRef.submit();
    },
    tokenCreated(token) {
      // eslint-disable-next-line no-console
      this.$store
        .dispatch('billing/sendToken', { token: token.id })
        .then(() => {
          if (this.card) {
            this.loading = false;
            bus.$emit('newToastMessage', 'Card updated.');
            this.$router.replace(
              frontendURL(`accounts/${this.accountId}/billing/portal`)
            );
          } else {
            this.subscribe();
          }
        })
        .catch(() => {
          bus.$emit('newToastMessage', 'Your card was declined.');
        });
    },
    subscribe() {
      this.$store.dispatch('billing/subscribe').then(() => {
        this.loading = false;
        bus.$emit('newToastMessage', 'Sucessfully Subscribed.');
        this.$router.replace(
          frontendURL(`accounts/${this.accountId}/settings/inboxes/new`)
        );
        this.$store.dispatch('isSubscribed');
      });
    },
    getCards() {
      this.$store.dispatch('billing/getCard').then(() => {
        if (this.card) {
          this.hasCard = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/variables';
.heading-jumbo {
  font-size: $font-size-big;
  font-weight: 700;
  margin: var(--space-medium) 0;
}
.card {
  margin: var(--space-normal) 0px !important;
  padding: var(--space-one) !important;
  border: 2px solid #40a5f8;
  max-width: 233px;
  box-shadow: var(--shadow-small);
  color: #40a5f8;
}
.stripeCard {
  margin-bottom: var(--space-small);
}
.label {
  font-weight: 400 !important;
  padding: var(--zero) !important;
  color: unset !important;
  font-size: $font-size-small !important;
}
.email-input {
  border: 1px solid transparent !important;
  box-shadow: 0 1px 3px 0 #e6ebf1 !important;
}
.email-input:focus {
  box-shadow: 0 1px 3px 0 #cfd7df !important;
  border: 1px solid transparent !important;
}
.email-input:active {
  box-shadow: 0 1px 3px 0 #cfd7df !important;
  border: 1px solid transparent !important;
}
.error input[type='email'] {
  border: 1px solid #fa755a !important;
}
.error .message {
  font-size: $font-size-micro !important;
  color: #fa755a !important;
}
.description-text {
  font-size: $font-size-mini !important;
  margin: var(--space-large) 0px var(--space-jumbo);
}
.description-second {
  font-size: $font-size-mini !important;
  margin-top: var(--space-normal);
}
</style>
<style>
#stripe-element-errors {
  color: #fa755a !important;
}
</style>
