import Vue from 'vue';
import * as types from '../mutation-types';
import voice from '../../api/voice';

const state = {
  response: null,
};

export const getters = {
  getVoiceNote($state) {
    return $state.response;
  },
};

export const actions = {
  fetchUrl: async ({ commit }, id) => {
    try {
      const response = await voice.getVoice(id);
      commit(types.default.SET_VOICE_NOTE, response.data);
    } catch (error) {
      // console.log(error);
    }
  },
};

export const mutations = {
  [types.default.SET_VOICE_NOTE]: ($state, data) => {
    Vue.set($state, 'response', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
