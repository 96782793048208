<template>
  <div class="medium-10 column signup">
    <div class="text-center medium-12 signup--hero">
      <div class="row align-center justify-space-between">
        <button
          v-for="(tab, index) in signupSteps"
          :key="index"
          :disabled="tab.id > activeTab"
          class="row medium-2 button button-align"
          @click="changeTab(tab.id)"
        >
          <div class="icon" :class="{ 'blue-icon': tab.id === activeTab }">
            <fluent-icon :icon="tab.icon" size="18" />
          </div>
          <div class="text-left column">
            <h6 class="heading">{{ tab.heading }}</h6>
            <span class="grey-text">{{ tab.sub_heading }}</span>
          </div>
          <fluent-icon class="chevron-color" icon="chevron-right" size="18" />
        </button>
      </div>
    </div>
    <div class="row align-center">
      <div class="small-12 medium-6 large-5 column">
        <information-form
          v-if="activeTab === 0"
          :is-signup-in-progress="isSignupInProgress"
          @submit="verifyStepOne"
        />
        <verification-form
          v-else-if="activeTab === 1"
          :is-signup-in-progress="isSignupInProgress"
          @submit="verifyStepTwo"
        />
        <div class="column text-center sigin--footer">
          <span>{{ $t('REGISTER.HAVE_AN_ACCOUNT') }}</span>
          <router-link to="/app/login">
            {{
              useInstallationName(
                $t('LOGIN.TITLE'),
                globalConfig.installationName
              )
            }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from '../../api/auth';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { DEFAULT_REDIRECT_URL } from '../../constants';
import informationForm from './informationForm.vue';
import verificationForm from './verificationForm.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
export default {
  components: { informationForm, verificationForm },
  mixins: [globalConfigMixin, alertMixin],
  data() {
    return {
      isSignupInProgress: false,
      error: '',
      creds: null,
      activeTab: 0,
      signupSteps: [
        {
          id: 0,
          heading: 'Basic Info',
          sub_heading: 'Enter details',
          icon: 'home',
        },
        {
          id: 1,
          heading: 'Verification',
          sub_heading: 'Verify Account',
          icon: 'person',
        },
        {
          id: 2,
          heading: 'Billing',
          sub_heading: 'Payment details',
          icon: 'credit-card-person',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
  },
  methods: {
    async submit() {
      this.isSignupInProgress = true;
      try {
        const response = await Auth.register(this.creds);
        if (response.status === 200) {
          window.location = DEFAULT_REDIRECT_URL + 'accounts';
        }
      } catch (error) {
        let errorMessage = this.$t('REGISTER.API.ERROR_MESSAGE');
        if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showAlert(errorMessage);
      } finally {
        this.isSignupInProgress = false;
      }
    },
    verifyStepOne(credentials) {
      this.creds = credentials;
      this.creds.step = 0;
      this.creds.verification_code = '';
      this.verifyPhoneNumber(this.creds);
    },
    verifyStepTwo(code) {
      this.creds.step = 1;
      this.creds.verification_code = code;
      this.verifyPhoneNumber(this.creds);
    },
    async verifyPhoneNumber(credentials) {
      this.isSignupInProgress = true;
      try {
        const response = await Auth.validateNumber(credentials);
        if (response.status === 200) {
          if (credentials.step === 1) {
            this.submit();
          } else {
            this.changeTab(credentials.step + 1);
          }
        }
      } catch (error) {
        let errorMessage = this.$t('REGISTER.API.ERROR_MESSAGE');
        if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showAlert(errorMessage);
      } finally {
        this.isSignupInProgress = false;
      }
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
<style scoped lang="scss">
.signup {
  .signup--hero {
    margin-bottom: var(--space-larger);

    .hero--logo {
      width: 180px;
    }

    .hero--title {
      margin-top: var(--space-default);
      font-weight: var(--font-weight-light);
    }
  }

  .signup--box {
    padding: var(--space-large);

    label {
      font-size: var(--font-size-default);
      color: var(--b-600);

      input {
        padding: var(--space-slab);
        height: var(--space-larger);
        font-size: var(--font-size-default);
      }
    }
  }

  .sigin--footer {
    padding: var(--space-medium);
    font-size: var(--font-size-default);

    > a {
      font-weight: var(--font-weight-bold);
    }
  }

  .accept--terms {
    font-size: var(--font-size-small);
    text-align: center;
    margin: var(--space-normal) 0 0 0;
  }

  .h-captcha--box {
    margin-bottom: var(--space-one);

    .captcha-error {
      color: var(--r-400);
      font-size: var(--font-size-small);
    }

    &::v-deep .error {
      iframe {
        border: 1px solid var(--r-500);
        border-radius: var(--border-radius-normal);
      }
    }
  }
  .grey-text {
    color: rgb(167, 166, 166);
    font-size: 12px;
  }
  .heading {
    margin: 0;
  }
  .button-align {
    align-items: center;
  }
  .blue-icon {
    background-color: #1f93ff;
    color: #fff;
  }
  .icon {
    padding: 7px 7px 5px;
    border-radius: 4px;
    margin-right: 15px;
  }
  .button {
    background: none !important;
    color: unset !important;
    padding-left: 0 !important;
  }
}
</style>
