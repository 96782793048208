// import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';
import ScheduleApi from '../../api/schedule';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getAllSchedules($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    try {
      const response = await ScheduleApi.getschedules();
      commit(types.SET_SCHEDULE_MESSAGES, response.data);
    } catch (error) {
      // Ignore error
    }
  },
  // eslint-disable-next-line no-unused-vars
  update: async ({ commit }, data) => {
    try {
      const response = await ScheduleApi.update(data);
      return response.data;
    } catch (error) {
      // Ignore error
      return throwErrorMessage(error);
    }
  },
  // eslint-disable-next-line no-unused-vars
  delete: async ({ commit }, data) => {
    try {
      const response = await ScheduleApi.delete(data.messageId);
      return response.data;
    } catch (error) {
      // Ignore error
      return throwErrorMessage(error);
    }
  },
};

export const mutations = {
  [types.SET_SCHEDULE_MESSAGES]($state, data) {
    $state.records = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
