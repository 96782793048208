/* global axios */
import ApiClient from '../ApiClient';

class TwilioChannel extends ApiClient {
  constructor() {
    super('channels/twilio_channel', { accountScoped: true });
  }

  saveHostedNumber(hosted_number) {
    return axios.post(`${this.url}/update_hosted_number`, hosted_number);
  }

  removeHostedNumber() {
    return axios.post(`${this.url}/remove_hosted_number`);
  }

  fetchHostedNumber() {
    return axios.get(`${this.url}/fetch_hosted_number`);
  }
}

export default new TwilioChannel();
