<template>
  <div class="columns profile--settings">
    <importChats />
  </div>
</template>

<script>
import importChats from './ImportChats.vue';

export default {
  components: {
    importChats,
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings {
  padding: 24px;
  overflow: auto;
}

.profile--settings--row {
  @include border-normal-bottom;
  padding: $space-normal;
  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }
  .small-9 {
    padding: $space-normal;
  }
}
.sub-heading-font {
  font-size: var(--font-size-mini);
}
.save-btn-width {
  width: 120px;
}
.mar-right {
  margin-right: 16px;
}
.padding-zero {
  padding: 0 !important;
}
</style>
