<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CANNED_MGMT.ADD.TITLE')"
        :header-content="showAiMode ? '' : $t('CANNED_MGMT.ADD.DESC')"
      />
      <GenerateWithAiHeader v-if="!showAiMode" @clicked="showAiMode = true" />
      <AIResponse
        v-if="showAiMode"
        @cancel="showAiMode = false"
        @updateBody="content = $event"
      />
      <form v-else class="row" @submit.prevent="addAgent()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.shortCode.$error }">
            {{ $t('CANNED_MGMT.ADD.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="shortCode"
              type="text"
              :placeholder="$t('CANNED_MGMT.ADD.FORM.SHORT_CODE.PLACEHOLDER')"
              @input="$v.shortCode.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.content.$error }">
            <div class="flex">
              <p class="">
                {{ $t('CANNED_MGMT.ADD.FORM.CONTENT.LABEL') }}
              </p>
              <p
                v-if="content"
                class="ml-auto generate-with-ai"
                @click="shortTheContent"
              >
                Shorten
              </p>
            </div>
            <textarea
              v-model.trim="content"
              rows="5"
              type="text"
              :placeholder="$t('CANNED_MGMT.ADD.FORM.CONTENT.PLACEHOLDER')"
              @input="$v.content.$touch"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                  $v.shortCode.$invalid ||
                  addCanned.showLoading
              "
              :button-text="$t('CANNED_MGMT.ADD.FORM.SUBMIT')"
              :loading="addCanned.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import alertMixin from 'shared/mixins/alertMixin';
import AIResponse from './AIResponse.vue';
import GenerateWithAiHeader from './GenerateWithAiHeader.vue';
export default {
  components: {
    WootSubmitButton,
    Modal,
    AIResponse,
    GenerateWithAiHeader,
  },
  mixins: [alertMixin],
  props: {
    responseContent: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      shortCode: '',
      content: this.responseContent || '',
      addCanned: {
        showLoading: false,
        message: '',
      },
      show: true,
      showAiMode: false,
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
  },
  methods: {
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    addAgent() {
      // Show loading on button
      this.addCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('createCannedResponse', {
          short_code: this.shortCode,
          content: this.content,
        })
        .then(() => {
          // Reset Form, Show success message
          this.addCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.ADD.API.SUCCESS_MESSAGE'));
          this.resetForm();
          this.onClose();
        })
        .catch(() => {
          this.addCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.ADD.API.ERROR_MESSAGE'));
        });
    },
    shortTheContent() {
      this.addCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('getAiContent', {
          shorten: true,
          response: this.content,
        })
        .then(res => {
          // Reset Form, Show success message
          this.addCanned.showLoading = false;
          this.content = res.response;
          this.showAlert('Response is shorten successfully');
        })
        .catch(() => {
          this.addCanned.showLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.ml-auto {
  margin-left: auto;
}
.flex {
  display: flex;
}
.w-full {
  width: 100%;
}
.generate-with-ai {
  color: rgb(62, 62, 250);
  padding-bottom: 2px;
  cursor: pointer;
}
</style>
