<template>
  <div
    class="conversation-details-wrap"
    :class="{ 'with-border-left': !isOnExpandedLayout }"
  >
    <new-conversation-header />
    <div class="messages-and-sidebar">
      <messages-view :inbox-id="inboxId" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import NewConversationHeader from './NewConversationHeader';
import MessagesView from './MessagesView';

export default {
  components: {
    NewConversationHeader,
    MessagesView,
  },

  props: {
    inboxId: {
      type: [Number, String],
      default: '',
      required: false,
    },
    isContactPanelOpen: {
      type: Boolean,
      default: true,
    },
    isOnExpandedLayout: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return { activeIndex: 0 };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      inboxes: 'inboxes/getAllInbox',
    }),
  },
  mounted() {
    if (!this.inboxes.length) {
      this.$store.dispatch('inboxes/getAllInbox');
    }
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/woot';

.conversation-details-wrap {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  background: var(--color-background-light);

  &.with-border-left {
    border-left: 1px solid var(--s-74);
  }
}

.dashboard-app--tabs {
  background: var(--white);
  margin-top: -1px;
  min-height: var(--dashboard-app-tabs-height);
}

.messages-and-sidebar {
  display: flex;
  background: var(--color-background-light);
  margin: 0;
  height: 100%;
  min-height: 0;
}

.conversation-sidebar-wrap {
  height: auto;
  flex: 0 0;
  overflow: hidden;
  overflow: auto;
  background: white;
  flex-basis: 28rem;

  @include breakpoint(large up) {
    flex-basis: 30em;
  }

  @include breakpoint(xlarge up) {
    flex-basis: 31em;
  }

  @include breakpoint(xxlarge up) {
    flex-basis: 33rem;
  }

  @include breakpoint(xxxlarge up) {
    flex-basis: 40rem;
  }

  &::v-deep .contact--panel {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}
</style>
