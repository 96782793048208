import BillingApi from '../../api/billing';
import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';

export const initialState = {
  plans: [],
  cards: null,
  current_package: null,
  payments_history: [],
  uiFlags: {
    isUpdating: false,
    isError: false,
  },
};

export const getters = {
  getPlans(state) {
    return state.plans;
  },
  getUIFlags(state) {
    return state.uiFlags;
  },
  getCards(state) {
    return state.cards;
  },
  getCurrentPackage(state) {
    return state.current_package;
  },
  getPayments(state) {
    return state.payments_history;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_BILLING_PLANS, []);
    try {
      const response = await BillingApi.getPlans();
      commit(types.SET_BILLING_PLANS, response.data);
    } catch (error) {
      // ignore error
    }
  },
  send: async ({ commit }, current_package) => {
    commit(types.SET_PLAN_UPDATE, {
      isUpdating: true,
    });
    try {
      const response = await BillingApi.sendPlans(current_package);
      // eslint-disable-next-line no-console
      console.log(response);
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: false,
      });
    } catch (error) {
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: true,
      });
    }
  },
  sendToken: async ({ commit }, { token }) => {
    commit(types.SET_PLAN_UPDATE, {
      isUpdating: true,
    });
    try {
      const response = await BillingApi.sendtoken({ token });
      // eslint-disable-next-line no-console
      console.log(response);
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: false,
      });
    } catch (error) {
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: true,
      });
      throw new Error(error);
    }
  },
  subscribe: async ({ commit }) => {
    commit(types.SET_PLAN_UPDATE, {
      isUpdating: true,
    });
    try {
      const response = await BillingApi.subscribe();
      // eslint-disable-next-line no-console
      console.log(response);
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: false,
      });
    } catch (error) {
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: true,
      });
      throw new Error(error);
    }
  },
  getCard: async ({ commit }) => {
    commit(types.SET_PLAN_UPDATE, {
      isUpdating: true,
    });
    try {
      const response = await BillingApi.fetchCard();
      // eslint-disable-next-line no-console
      commit(types.SET_CARDS, response.data.card);
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: false,
      });
    } catch (error) {
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: true,
      });
    }
  },
  getPaymentsHistory: async ({ commit }) => {
    commit(types.SET_PLAN_UPDATE, {
      isUpdating: true,
    });
    try {
      const response = await BillingApi.getHistory();
      // eslint-disable-next-line no-console
      if (response.data) {
        commit(types.SET_HISTORY, response.data);
      }
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: false,
      });
    } catch (error) {
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: true,
      });
    }
  },
  getCurrentPackage: async ({ commit }, { id }) => {
    commit(types.SET_PLAN_UPDATE, {
      isUpdating: true,
    });
    try {
      const response = await BillingApi.fetchCurrentPackage(id);
      // eslint-disable-next-line no-console
      commit(types.SET_CURRENT_PACKAGE, response.data);
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: false,
      });
    } catch (error) {
      commit(types.SET_PLAN_UPDATE, {
        isUpdating: false,
        isError: true,
      });
    }
  },
  // eslint-disable-next-line no-unused-vars
  sendContact: async ({ commit }, data) => {
    try {
      const response = await BillingApi.postContact(data);
      return response.data;
    } catch (error) {
      // ignore error
      return throwErrorMessage(error);
    }
  },
};

export const mutations = {
  [types.SET_BILLING_PLANS](state, plans) {
    state.plans = plans;
  },
  [types.SET_CARDS](state, cards) {
    state.cards = cards;
  },
  [types.SET_HISTORY](state, payments_history) {
    state.payments_history = payments_history;
  },
  [types.SET_CURRENT_PACKAGE](state, current_package) {
    state.current_package = current_package;
  },
  [types.SET_PLAN_UPDATE](state, uiFlags) {
    state.uiFlags = { ...state.uiFlags, ...uiFlags };
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
