/* global axios */

export default {
  searchNumbers(areaCode, accountId) {
    return new Promise(resolve => {
      axios
        .post(
          `/api/v2/accounts/${accountId}/text_messages/search_available_numbers.json`,
          {
            area_code: areaCode,
          }
        )
        .then(response => {
          resolve(response.data);
        });
    });
  },

  smsChannelNotPresent(accountId) {
    return new Promise(resolve => {
      axios
        .post(
          `/api/v2/accounts/${accountId}/text_messages/sms_channel_not_present.json`
        )
        .then(response => {
          resolve(response.data);
        });
    });
  },
};
