<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box text-left">
      <woot-modal-header :header-title="pageTitle" />
      <form class="row medium-8" @submit.prevent="editScheduledMessage()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.schedule_time.$error }">
            {{ $t('SCHEDULE_MESSAGE_SETTINGS.EDIT.FORM.SHORT_CODE.LABEL') }}
            <input
              id="edit_date_picker"
              v-model="schedule_time"
              v-tooltip.top-end="'Schedule message'"
              type="datetime-local"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.content.$error }">
            {{ $t('SCHEDULE_MESSAGE_SETTINGS.EDIT.FORM.CONTENT.LABEL') }}
            <textarea
              v-model.trim="content"
              rows="5"
              type="text"
              :placeholder="
                $t('SCHEDULE_MESSAGE_SETTINGS.EDIT.FORM.CONTENT.PLACEHOLDER')
              "
              @input="$v.content.$touch"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                  $v.schedule_time.$invalid ||
                  editCanned.showLoading
              "
              :button-text="$t('SCHEDULE_MESSAGE_SETTINGS.EDIT.FORM.SUBMIT')"
              :loading="editCanned.showLoading"
            />
            <button
              class="button clear"
              @click.prevent="$emit('deleteSchedule')"
            >
              {{ $t('SCHEDULE_MESSAGE_SETTINGS.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
/* eslint no-console: 0 */
import { required } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  props: {
    id: { type: Number, default: null },
    edcontent: { type: String, default: '' },
    edschedule: { type: String, default: '' },
    onClose: { type: Function, default: () => {} },
  },
  data() {
    return {
      editCanned: {
        showAlert: false,
        showLoading: false,
        message: '',
      },
      schedule_time: this.edschedule.replace('Z', ''),
      content: this.edcontent,
      show: true,
    };
  },
  validations: {
    schedule_time: {
      required,
    },
    content: {
      required,
    },
  },
  computed: {
    pageTitle() {
      return `${this.$t('SCHEDULE_MESSAGE_SETTINGS.EDIT.TITLE')}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      let datepicker = document.getElementById('edit_date_picker');
      let today = new Date();
      today.setSeconds(0, 0);
      datepicker.min = today.toISOString().replace('Z', '');
    });
  },
  methods: {
    setPageName({ name }) {
      this.$v.content.$touch();
      this.content = name;
    },
    showAlert() {
      bus.$emit('newToastMessage', this.editCanned.message);
    },
    resetForm() {
      this.schedule_time = '';
      this.content = '';
      this.$v.schedule_time.$reset();
      this.$v.content.$reset();
    },
    editScheduledMessage() {
      // Show loading on button
      this.editCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('scheduleMessages/update', {
          id: this.id,
          schedule_date: this.schedule_time + 'ZZ',
          content: this.content,
        })
        .then(response => {
          // Reset Form, Show success message
          this.editCanned.showLoading = false;
          this.editCanned.message = 'Scheduled Message updated Successfully';
          this.showAlert();
          this.resetForm();
          this.$emit('updateSchedule');
          console.log(response);
          this.$store.dispatch('updateMessage', response);
          setTimeout(() => {
            this.onClose();
          }, 10);
        })
        .catch(() => {
          this.editCanned.showLoading = false;
          this.editCanned.message = 'Error! while updating Scheduled message';
          this.showAlert();
        });
    },
  },
};
</script>
