<template>
  <div class="row text-left">
    <div v-for="(plan, index) in plansList" :key="index" class="card medium-5">
      <div class="card-body">
        <div class="row justify-space-between">
          <h5 class="plan-name block-title column">{{ plan.title }}</h5>
          <div class="column">
            <h3 class="page-title">${{ plan.price_month }}/mo</h3>
          </div>
        </div>
        <div class="plan-benefits">
          <p
            v-for="benefit in plan.benefits"
            :key="benefit"
            class="plan-benefits-list"
          >
            <img
              src="~dashboard/assets/images/plan_tick.svg"
              alt="No benefit"
            />
            {{ benefit }}
          </p>
        </div>
        <div class="column btn-width">
          <woot-button
            :is-expanded="true"
            :is-loading="loading"
            :is-disabled="selectedPlanId === plan.id"
            @click="submitPlan(plan.id)"
          >
            Buy Plan
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { frontendURL } from '../../../../helper/URLHelper';
export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      plansList: 'billing/getPlans',
      selected_package: 'billing/getCurrentPackage',
      card: 'billing/getCards',
    }),
    selectedPlanId() {
      if (this.selected_package?.package_details) {
        return this.selected_package.package_details.id;
      }
      return null;
    },
  },
  mounted() {
    this.getCards();
    this.getCurrentPackage();
    this.fetchPlans();
  },
  methods: {
    frontendURL,
    submitPlan(planId) {
      this.loading = true;
      let current_package = {
        package_id: planId,
        status: 'unpaid',
      };
      this.$store
        .dispatch('billing/send', current_package)
        .then(() => {
          if (this.card) {
            this.subscribe();
          } else {
            this.loading = false;
            bus.$emit('newToastMessage', 'Payment Plan updated.');
            this.$router.push(
              frontendURL(
                `accounts/${this.accountId}/billing/portal/payment-method`
              )
            );
          }
        })
        .catch(() => {
          this.loading = false;
          // eslint-disable-next-line no-console
          console.log('error');
        });
    },
    fetchPlans() {
      this.$store.dispatch('billing/get');
    },
    subscribe() {
      this.$store.dispatch('billing/subscribe').then(() => {
        this.loading = false;
        bus.$emit('newToastMessage', 'Sucessfully Subscribed.');
        this.$router.replace(
          frontendURL(`accounts/${this.accountId}/billing/portal`)
        );
      });
    },
    getCards() {
      this.$store.dispatch('billing/getCard').then(() => {
        if (this.card) {
          this.hasCard = true;
        }
      });
    },
    getCurrentPackage() {
      let id = this.accountId;
      this.$store.dispatch('billing/getCurrentPackage', { id }).then(() => {
        if (this.current_package) {
          this.hasPlan = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/variables';
.card {
  margin: var(--space-small) !important;
  padding: var(--space-medium) var(--space-normal) !important;
  box-shadow: var(--shadow-larger);
}
.card-body {
  .metric-content {
    padding-bottom: var(--space-small);
    .heading {
      font-size: var(--font-size-default);
    }
    .metric {
      color: var(--w-800);
      font-size: var(--font-size-bigger);
      margin-bottom: var(--zero);
      margin-top: var(--space-smaller);
    }
  }
}
.plan-name {
  color: #00c4c4;
}
.plan-benefits {
  margin-top: 20px;
}
.btn-width {
  margin: 20px auto 0;
  min-width: auto;
  max-width: 333px;
}
.plan-benefits-list {
  font-size: $font-size-micro;
  img {
    margin: var(--space-smaller);
  }
}
</style>
