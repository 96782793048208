import SettingsContent from '../Wrapper';
import CannedHome from './Index';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/schedule_message'),
      component: SettingsContent,
      props: {
        headerTitle: 'SCHEDULE_MESSAGE_SETTINGS.TITLE',
        icon: 'chat-multiple',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'scheduled_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'schedule_message_list',
          roles: ['administrator', 'agent'],
          component: CannedHome,
        },
      ],
    },
  ],
};
