<template>
  <div class="message-text__wrap show--quoted" @click="$emit('edit-schedule')">
    <span class="schedule-time">
      <fluent-icon icon="calendar-clock" class="fluent-icon" size="12" />
      <small>
        {{ scheduleTime }}
      </small>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    scheduleTime: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showQuotedContent: false,
    };
  },
  methods: {
    toggleQuotedContent() {
      this.showQuotedContent = !this.showQuotedContent;
    },
  },
};
</script>
<style lang="scss">
.text-content {
  overflow: auto;

  ul,
  ol {
    padding-left: var(--space-two);
  }
  table {
    margin: 0;
    border: 0;

    td {
      margin: 0;
      border: 0;
    }

    tr {
      border-bottom: 0 !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--font-size-normal);
  }
}

.show--quoted {
  blockquote {
    display: block;
  }
}

.hide--quoted {
  blockquote {
    display: none;
  }
}

.quoted-text--button {
  color: var(--s-400);
  cursor: pointer;
  font-size: var(--font-size-mini);
  padding-bottom: var(--space-small);
  padding-top: var(--space-small);

  .fluent-icon {
    margin-top: var(--space-minus-smaller);
  }
}
.schedule-time {
  color: red;
  cursor: pointer;
  background: aliceblue;
  border-radius: 6px;
  padding: 0 5px;
}
</style>
