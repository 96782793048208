<template>
  <div class="flex ">
    <div class="image" :class="[hideAvatar ? 'invisible' : '']">
      <img :src="image" />
    </div>
    <div class="paragraph">
      <p class="p">{{ paragraph }}</p>
    </div>
  </div>
</template>

<script>
import image from 'shared/assets/images/fav-icon.png';

export default {
  components: {
    /* Icon */
  },
  props: {
    paragraph: { type: String, default: '' },
    hideAvatar: { type: Boolean, default: false },
  },
  data() {
    return { image };
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  gap: 12px;
  margin-bottom: 8px;
}
.image {
  padding: 6px;
  margin-left: -6px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paragraph {
  border-radius: 99999px;
  background-color: #f4f4f4;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  font-size: 20px;
  color: black;
}
.invisible {
  visibility: hidden;
}
.p {
  font-size: 20px !important;
}
</style>
