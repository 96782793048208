<template>
  <div class="columns bg-light">
    <settings-header
      button-route="new"
      :icon="icon"
      :header-title="$t(headerTitle)"
      :button-text="$t(headerButtonText)"
      :show-back-button="showBackButton"
      :back-url="backUrl"
      :show-sidemenu-icon="showSidemenuIcon"
    />
    <router-view />
  </div>
</template>
<script>
import SettingsHeader from '../settings/SettingsHeader.vue';
export default {
  components: {
    SettingsHeader,
  },
  props: {
    headerTitle: { type: String, default: '' },
    headerButtonText: { type: String, default: '' },
    icon: { type: String, default: '' },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    backUrl: {
      type: [String, Object],
      default: '',
    },
    showSidemenuIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
