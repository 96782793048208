<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('KEYWORDS_SETTINGS.HEADER_BTN_TXT') }}
    </woot-button>

    <div class="row">
      <metric-card
        :header="this.$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.HEADER')"
      >
        <keyword-table
          :page-index="pageIndex"
          :is-loading="uiFlags.isFetchingAgentConversationMetric"
          @edit-keyword="openEditPopup"
          @delete-keyword="openDeletePopup"
          @update-status="updateStatus"
        />
      </metric-card>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-keyword :on-close="hideAddPopup" />
    </woot-modal>

    <!-- Edit Canned Response -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-keyword
        v-if="showEditPopup"
        :id="selectedResponse.id"
        :edshort-code="selectedResponse.keyword"
        :edcontent="selectedResponse.response_text"
        :on-close="hideEditPopup"
      />
    </woot-modal>

    <!-- Delete Canned Response -->
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CANNED_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('CANNED_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AddKeyword from './AddKeyword';
import EditKeyword from './EditKeyword';
import KeywordTable from './components/KeywordTable';
import MetricCard from './components/MetricCard';

export default {
  components: {
    AddKeyword,
    EditKeyword,
    KeywordTable,
    MetricCard,
  },
  data() {
    return {
      loading: {},
      pageIndex: 1,
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      cannedResponseAPI: {
        message: '',
      },
      keywordMetric: [],
    };
  },
  computed: {
    ...mapGetters({
      records: 'getCannedResponses',
      uiFlags: 'getUIFlags',
      agents: 'agents/getAgents',
      agentConversationMetric: 'getAgentConversationMetric',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.keyword
      }`;
    },
    deleteRejectText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.keyword
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedResponse.keyword}?`;
    },
  },
  mounted() {
    // Fetch API Cal
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.selectedResponse.id] = false;
      this.selectedResponse = {};
      // Show message
      this.cannedResponseAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Modal Functions
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedResponse = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Modal Functions
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteCannedResponse(this.selectedResponse.id);
    },
    deleteCannedResponse(id) {
      this.$store
        .dispatch('chat_keywords/delete', id)
        .then(() => {
          this.$store.dispatch('chat_keywords/get');
          this.showAlert('Keyword deleted successfully');
        })
        .catch(() => {
          this.showAlert('Error! while deleting keyword');
        });
    },
    updateStatus(response) {
      this.$store
        .dispatch('chat_keywords/update_status', response)
        .then(() => {
          this.showAlert('Keyword Status updated successfully');
        })
        .catch(() => {
          this.showAlert('Error! while updating keyword status');
        });
    },
  },
};
</script>
<style scoped>
.short-code {
  width: 14rem;
}
</style>
