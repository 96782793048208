<template>
  <div class="columns profile--settings">
    <hostedNumber />
    <!-- <autoResponse /> -->

    <woot-loading-state v-if="uiFlags.isFetchingItem" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import hostedNumber from './hostedNumber.vue';
// import autoResponse from './AutoResponse.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
// import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';

export default {
  components: {
    hostedNumber,
    // autoResponse,
  },
  mixins: [alertMixin],
  data() {
    return {
      keyword: '',
      keywordDescription: '',
      locale: 'en',
      domain: '',
      supportEmail: '',
      features: {},
      autoResolveDuration: null,
      latestChatwootVersion: null,
    };
  },
  validations: {
    keywordDescription: {
      required,
    },
    keyword: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
    }),
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    isUpdating() {
      return this.uiFlags.isUpdating;
    },
  },
  mounted() {
    this.$store.dispatch('conversationSetting/get');
  },
  methods: {
    async updateAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'));
      }
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings {
  padding: 24px;
  overflow: auto;
}

.profile--settings--row {
  @include border-normal-bottom;
  padding: $space-normal;
  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }
  .small-9 {
    padding: $space-normal;
  }
}
.sub-heading-font {
  font-size: var(--font-size-mini);
}
.save-btn-width {
  width: 120px;
}
.mar-right {
  margin-right: 16px;
}
.padding-zero {
  padding: 0 !important;
}
</style>
