import { frontendURL } from '../../../../helper/URLHelper';

const billing = accountId => ({
  parentNav: 'billing',
  routes: ['settings_account_campaigns'],
  menuItems: [
    {
      icon: 'credit-card-person',
      label: 'Billing',
      key: 'billingPlan',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/billing`),
      toStateName: 'billing_plan_index',
    },
  ],
});

export default billing;
