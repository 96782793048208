<template>
  <div class="column content-box">
    <p>
      Import chats through a CSV file.
      <a :href="csvUrl" download="import-chats-sample">{{
        $t('IMPORT_CONTACTS.DOWNLOAD_LABEL')
      }}</a>
    </p>
    <div class="row modal-content">
      <div class="medium-12 columns">
        <label>
          <span>{{ $t('IMPORT_CONTACTS.FORM.LABEL') }}</span>
          <input
            id="file"
            ref="file"
            type="file"
            accept="text/csv"
            @change="handleFileUpload"
          />
        </label>
      </div>
      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-button
            :disabled="uiFlags.isCreating || !file"
            :loading="uiFlags.isCreating"
            @click="uploadFile"
          >
            {{ $t('IMPORT_CONTACTS.FORM.SUBMIT') }}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      file: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
    csvUrl() {
      return '/downloads/messages_import.csv';
    },
  },
  methods: {
    async uploadFile() {
      try {
        if (!this.file) return;
        await this.$store.dispatch('contacts/importChat', this.file);
        this.file = '';
        this.$refs.file.value = null;
        this.showAlert(
          'Chats are importing in background, Continue using other system'
        );
      } catch (error) {
        this.file = '';
        this.$refs.file.value = null;
        this.showAlert(error.message || 'Error while importing Chats.');
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>
