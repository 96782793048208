import Index from './Index.vue';
import Wrapper from './BillingWrapper';
import Portal from './components/BillingPortal';
import PortalForm from './components/PortalForm';
import Plans from './components/Plans';
import PaymentMethod from './components/PaymentMethod';
import { frontendURL } from '../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/billing'),
      component: Wrapper,
      roles: ['administrator'],
      props: params => {
        const showBackButton = params.name !== 'billing_plan_index';
        if (params.name === 'billing_portal') {
          return {
            headerTitle: 'BILLING_SETTINGS.PORTAL.TITLE',
            icon: 'credit-card-person',
            showNewButton: false,
            showBackButton,
          };
        }
        return {
          headerTitle: 'BILLING_SETTINGS.TITLE',
          icon: 'credit-card-person',
          showNewButton: false,
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'billing_plan_index',
          roles: ['administrator'],
          component: Index,
        },
        {
          path: 'portal',
          name: 'billing_portal',
          roles: ['administrator'],
          component: Portal,
          children: [
            {
              path: '',
              name: 'billing_portal_form',
              roles: ['administrator'],
              component: PortalForm,
            },
            {
              path: 'plans/list',
              name: 'billing_plans_list',
              roles: ['administrator'],
              component: Plans,
            },
            {
              path: 'payment-method',
              name: 'add_payment_method',
              roles: ['administrator'],
              component: PaymentMethod,
            },
          ],
        },
      ],
    },
  ],
};
