<template>
  <div class="modal-mask">
    <div v-on-clickaway="closeContactPanel" class="notification-wrap">
      <div class="header-wrap w-full flex-space-between">
        <div class="header-title--wrap flex-view">
          <span class="header-title">
            Contact Us
          </span>
        </div>
      </div>
      <div class="contact-form">
        <div class="columns profile--settings">
          <form @submit.prevent="submit">
            <div class="small-12 row">
              <div class="columns">
                <label :class="{ error: $v.name.$error }">
                  Name
                  <input
                    v-model="name"
                    type="text"
                    placeholder="Enter name"
                    @blur="$v.name.$touch"
                  />
                  <span v-if="$v.name.$error" class="message">
                    {{ $t('GENERAL_SETTINGS.FORM.NAME.ERROR') }}
                  </span>
                </label>
                <label :class="{ error: $v.email.$error }">
                  Email
                  <input
                    v-model="email"
                    type="email"
                    placeholder="Enter email"
                    @blur="$v.email.$touch"
                  />
                  <span v-if="$v.email.$error" class="message">
                    Please enter a valid email
                  </span>
                </label>
                <label :class="{ error: $v.phone.$error }">
                  Phone
                  <input
                    v-model="phone"
                    type="Number"
                    placeholder="+91********"
                    @blur="$v.phone.$touch"
                  />
                  <span v-if="$v.phone.$error" class="message">
                    Please enter a valid Phone number
                  </span>
                </label>
                <label :class="{ error: $v.description.$error }">
                  Description
                  <woot-message-editor
                    id="message-signature-input"
                    v-model="description"
                    class="message-editor"
                    :is-format-mode="true"
                    placeholder="
                    Insert your message request
                  "
                    @blur="$v.description.$touch"
                  />
                  <span v-if="$v.description.$error" class="message">
                    Message is required
                  </span>
                </label>
                <woot-submit-button :is-disabled="false" button-text="Send" />
              </div>
            </div>
          </form>
        </div>
        <!-- <div class="footer-wrap flex-space-between">
          <span class="page-count"> {{ currentPage }} - {{ lastPage }} </span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';

export default {
  components: {
    WootMessageEditor,
  },
  mixins: [clickaway],
  data() {
    return {
      pageSize: 15,
      name: '',
      phone: '',
      email: '',
      description: '',
    };
  },
  validations: {
    name: {
      required,
    },
    phone: {
      required,
    },
    email: {
      required,
    },
    description: {
      required,
    },
    autoResolveDuration: {
      minValue: minValue(1),
      maxValue: maxValue(999),
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    closeContactPanel() {
      this.$emit('close');
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let data = {
        name: this.name,
        phoneNumber: this.phone,
        email: this.email,
        description: this.description,
      };
      this.$store.dispatch('billing/sendContact', data).then(response => {
        let message = response.message;
        bus.$emit('newToastMessage', message);
        this.closeContactPanel();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-view {
  display: flex;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.notification-wrap {
  flex-direction: column;
  height: 90vh;
  width: 52rem;
  background-color: var(--white);
  border-radius: var(--border-radius-medium);
  position: absolute;
  left: var(--space-jumbo);
  margin: var(--space-small);
}
.header-wrap {
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--s-50);
  padding: var(--space-two) var(--space-medium) var(--space-slab)
    var(--space-medium);

  .header-title--wrap {
    align-items: center;
  }

  .header-title {
    font-size: var(--font-size-two);
    font-weight: var(--font-weight-black);
  }

  .total-count {
    padding: var(--space-smaller) var(--space-small);
    background: var(--b-50);
    border-radius: var(--border-radius-rounded);
    font-size: var(--font-size-micro);
    font-weight: var(--font-weight-bold);
  }

  .action-button {
    padding: var(--space-micro) var(--space-small);
    margin-right: var(--space-small);
  }
}

.page-count {
  font-size: var(--font-size-micro);
  font-weight: var(--font-weight-bold);
  color: var(--s-500);
  margin: 0px auto;
}

.footer-wrap {
  align-items: center;
  padding: var(--space-smaller) var(--space-two);
}

.page-change--button:hover {
  background: var(--s-50);
}
.message-editor {
  margin-bottom: var(--space-two);
}
.contact-form {
  height: calc(90vh - 90px);
  overflow-y: auto;
}
</style>
