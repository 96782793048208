<template>
  <form @submit.prevent="updateHostedNumber()">
    <div class="small-12 row profile--settings--row">
      <div class="columns medium-4 small-3">
        <h4 class="block-title">
          Hosted Number
        </h4>
        <p class="sub-heading-font">
          Hosted SMS provides a method for customers to send and receive
          messages on voice-enabled numbers they already own as part of an
          established voice application.
        </p>
      </div>
      <div class="columns small-12 medium-8">
        <label :class="{ error: $v.hosted_number.$error }">
          Hosted Number
          <div class="flex-input">
            <input
              v-model="hosted_number"
              type="text"
              :disabled="userRole === 'agent'"
              placeholder="+1343*****"
              @blur="$v.hosted_number.$touch"
            />
            <woot-button
              v-if="hosted_number && userRole !== 'agent'"
              icon="dismiss"
              variant="clear"
              color-scheme="alert"
              @click.prevent="removeHostedNumber"
            />
          </div>
          <span v-if="$v.hosted_number.$error" class="message">
            Hosted Number is required
          </span>
        </label>
        <woot-button
          v-if="userRole !== 'agent'"
          :is-loading="UpdatingHostedNumber"
          type="submit"
        >
          Save Number
        </woot-button>
      </div>
    </div>
  </form>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';

export default {
  mixins: [alertMixin],
  data() {
    return {
      UpdatingHostedNumber: false,
    };
  },
  validations: {
    hosted_number: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      hostedNumber: 'conversationSetting/getHostedNumber',
      userRole: 'getCurrentRole',
    }),
    hosted_number: {
      get() {
        return this.hostedNumber;
      },
      set(value) {
        this.$store.dispatch('conversationSetting/setHostedNumber', value);
      },
    },
  },
  methods: {
    removeHostedNumber() {
      this.$store
        .dispatch('conversationSetting/removeHostedNumber')
        .then(() => {
          this.$store.dispatch('conversationSetting/get');
        });
    },
    async updateHostedNumber() {
      this.UpdatingHostedNumber = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert('Hosted Number is not valid.');
        this.UpdatingHostedNumber = false;
        return;
      }
      let data = {
        hosted_number: this.hosted_number,
      };
      this.$store
        .dispatch('conversationSetting/saveHostedNumber', data)
        .then(response => {
          this.showAlert(response.message);
          this.UpdatingHostedNumber = false;
          this.$store.dispatch('conversationSetting/get');
        })
        .catch(() => {
          this.showAlert('Error while updating hosted number.');
          this.UpdatingHostedNumber = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings {
  padding: 24px;
  overflow: auto;
}

.profile--settings--row {
  @include border-normal-bottom;
  padding: $space-normal;
  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }
  .small-9 {
    padding: $space-normal;
  }
}
.sub-heading-font {
  font-size: var(--font-size-mini);
}
.save-btn-width {
  width: 120px;
}
.mar-right {
  margin-right: 16px;
}
.padding-zero {
  padding: 0 !important;
}
.flex-input {
  display: flex;
}
</style>
