const website = () => ({
  parentNav: 'website',
  routes: [
    'website',
    'inbox_dashboard',
    'inbox_conversation',
    'conversation_through_inbox',
    'notifications_dashboard',
  ],
  menuItems: [],
});

export default website;
