/* global axios */
import ApiClient from './ApiClient';

class Voice extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  getVoice(id) {
    return axios.get(`${this.url}manage_audios/${id}`);
  }
}

export default new Voice();
